import styled from "styled-components";
import IconeY from "../../../assets/img/Y.svg";

export const Container = styled.div`
    position: relative;
    .container-card {
        height: 410px;
        margin: 0 auto;
        width: 258px;
        background-color: ${({ theme }) => theme.colors.secondary};
        border-radius: 16px;
        padding: 37px 28px 43px 28px;
        position: relative;
        z-index: 10;
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(${IconeY});
            background-size: cover;
            background-position: -25px 0px;
            background-repeat: no-repeat;
            border-radius: 8px;
            z-index: -1;
        }
        .container-logo {
            width: 64.53px;
            height: 38.89px;
            margin-bottom: 48px;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .container-saldo {
            width: 193px;
            height: 50px;
            border: 1px solid ${({ theme }) => theme.colors.white};
            border-radius: 8px;
            padding-left: 20px;
            padding-right: 8px;
            padding-top: 4px;
            display: flex;
            align-items: center;
            margin-bottom: 28px;
            &.active {
                .dinheiro {
                    &:before {
                        animation: shimmer 1.2s ease-in-out infinite;
                    }
                }
            }
            .dinheiro {
                display: flex;
                align-items: end;
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 24px;
                margin-right: 4px;
                margin-left: 4px;
                &.fisico {
                    &.show {
                        &::after {
                            opacity: 0;
                            transition: all 0.5s;
                        }
                        &::before {
                            opacity: 0;
                            transition: all 0.5s;
                        }
                    }
                    &:after {
                        background-image: linear-gradient(
                            90deg,
                            #50864c 12.24%,
                            #65a35f 107.14%
                        );
                    }
                    &:before {
                        background-image: linear-gradient(
                            90deg,
                            #50864c 12.24%,
                            #65a35f 107.14%
                        );
                    }
                }
                &.virtual {
                    &:after {
                        background-image: linear-gradient(
                            90deg,
                            #16343b 12.24%,
                            #1e5e6c 107.14%
                        );
                    }
                    &:before {
                        background-image: linear-gradient(
                            90deg,
                            #16343b 12.24%,
                            #1e5e6c 107.14%
                        );
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    color: grey;
                    display: inline-block;
                    background-repeat: no-repeat;
                    font-size: 50px;
                    max-width: 200px;
                    top: 50%;
                    left: 0px;
                    transform: translateY(-50%);
                    width: 100%;
                    height: 24px;
                    opacity: 1;
                    transition: opacity 0.5s;
                }
                &:before {
                    content: "";
                    position: absolute;
                    color: grey;
                    display: inline-block;
                    background-image: linear-gradient(
                        90deg,
                        #50864c 12.24%,
                        #65a35f 107.14%
                    );
                    background-repeat: no-repeat;
                    font-size: 50px;
                    max-width: 200px;
                    top: 0px;
                    left: 0px;
                    transform: translateX(-100%);
                    width: 100%;
                    height: 24px;
                    z-index: 99;
                    opacity: 1;
                    transition: opacity 0.5s;
                }
            }

            @keyframes shimmer {
                100% {
                    transform: translateX(100%);
                }
            }

            small {
                color: ${({ theme }) => theme.colors.white};
                font-size: 12px;
                position: relative;
                top: -6px;
                left: -4px;
            }

            p {
                color: ${({ theme }) => theme.colors.white};
                font-size: 29px;
                line-height: 24px;
            }
            .container-olho {
                margin-left: auto;
                position: relative;
                &.hide {
                    &:before {
                        transform: translate(-50%, -50%) rotate(-45deg) scale(0);
                        transition: transform 0.5s;
                    }
                }
                &:before {
                    content: "";
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    background-color: ${(props) => props.theme.colors.white};
                    border-radius: 8px;
                    z-index: 1;
                    transform: translate(-50%, -50%) rotate(-45deg) scale(1);
                    transition: transform 0.5s;
                }

                img {
                    width: 28px;
                }
            }
        }

        .container-numero-card {
            margin-bottom: 29px;
            p {
                color: ${({ theme }) => theme.colors.white};
                font-size: 20px;
                line-height: 20px;
            }
        }

        .container-informacoes {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 182px;
            margin-bottom: 63px;
            .container-validade,
            .container-cvv {
                span {
                    color: ${({ theme }) => theme.colors.white};
                    font-size: 12px;
                    line-height: 10px;
                }

                p {
                    color: ${({ theme }) => theme.colors.white};
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 600;
                }
            }
        }

        .title {
            h2 {
                color: ${({ theme }) => theme.colors.white};
                font-size: 16px;
                line-height: 16px;
                font-weight: 600;
            }
        }
    }
`;
