import styled from "styled-components";
import { Container as DefaultHeader } from "../Header/styles";
import imgLupa from "../../../assets/img/lupa.svg";
import { Flex } from "alisson-application";

export const Container = styled.div`
    .user {
        display: flex;
        align-items: center;
        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 10px;
        }
        cursor: pointer;
    }
`;

export const HeaderStyles = styled.div`
    display: flex;
    border-bottom: 1px solid #e1e2e3;
    justify-content: space-between;
    ${DefaultHeader} {
        border-bottom: unset;
    }
    .busca {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            width: auto;
            margin: 0px;
            margin-left: 11px;
        }
        .container-input-system {
            margin-bottom: 0px;
            width: 532px;
            position: relative;
            input {
                padding-left: 45px;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 13px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${imgLupa}) no-repeat;
                width: 19px;
                height: 17px;
                z-index: 20;
                transition: opacity 0.5s;
            }
        }
    }
`;

export const SubHeaderStyles = styled.div`
    width: 1147px;
    margin: 0 auto;
    margin-top: 28px;

    .container-cartao {
        display: grid;
        grid-template-columns: 340px 1fr;
        align-items: center;
        margin-bottom: 25px;
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
            display: flex;
            align-items: center;
            img {
                margin-right: 15px;
            }
        }

        .container-blocks {
            margin-left: 22px;
            display: flex;
            justify-content: flex-start;

            .container-blocks-button {
                width: 20%;
            }
        }
    }
`;

export const StylesBloquear = styled(Flex)`
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    .MuiSwitch-thumb {
        color: ${(props) => props.theme.colors.white} !important;
    }
    &.bloqueado {
        span {
            &.MuiSwitch-track {
                background-color: red !important;
            }
        }
    }
    &.desbloquear {
        span {
            &.MuiSwitch-track {
                background-color: ${(props) =>
                    props.theme.colors.secondary} !important;
            }
        }
    }
`;
