import styled from "styled-components";

export const EmptyStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        margin-bottom: 20.23px;
    }
    span{
        p{
            font-weight:400;
            text-transform: none;
            font-size: 14px;
            color:${(props) => props.theme.colors.textSubtle};
        }
        
    }
    p {
        margin:0 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;

        color: ${(props) => props.theme.colors.primaryDark};
    }
`;
