import { ChangeEvent, useEffect, useState } from "react";
import Mask from "react-input-mask";
import { Field, ErrorMessage, FormikProps, Formik, Form } from "formik";
import { maskCPF_CNPJ } from "../../util/helper";

import { useUser } from "../../Hook/user/UseUser";
import { useTranslation } from "../../contexts/Localization";
import { Button } from "../Mobile/ButtonSlim";
import { ButtonEmail } from "./button";

import { initalValuesCNPJ, MyformCNPJ, validationCNPJ } from "./config";
import { usePerfil } from "../../Hook/user/usePerfil";

export function FormPerfilCNPJ() {
    const { user, confirmEmailGenerate } = useUser();
    const { generateCode, formatValues, setDataSensible } = usePerfil();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [values, setValues] = useState(initalValuesCNPJ);

    async function handleGenarateEmail() {
        await confirmEmailGenerate();
    }

    useEffect(() => {
        if (Object.values(user).length > 0) {
            setValues({
                document: maskCPF_CNPJ(user.document),
                name: user?.name,
                email: user?.pessoa?.email ? user?.pessoa?.email : "",
                phone_number: user?.phone_number.replace("55", ""),
                apelido: user?.pessoa?.apelido,
                nome_cpf: user?.pessoa?.nome_cpf || "",
            });
        }
    }, [user]);

    return (
        <Formik
            enableReinitialize
            initialValues={values}
            validationSchema={validationCNPJ}
            onSubmit={async (values, actions) => {
                setLoading(true);
                const valuesFormated = formatValues(values as any);
                if (values.name.trim().indexOf(" ") === -1) {
                    actions.setFieldError("name", "Preencha o nome completo");
                    return;
                }

                values = {
                    ...values,
                    phone_number: `55${values.phone_number.replace(/\D/g, "")}`,
                    document: `${values.document.replace(/\D/g, "")}`,
                };

                await generateCode(valuesFormated.phone_number);
                setDataSensible(true);
                setLoading(false);
            }}
        >
            {(props: FormikProps<MyformCNPJ>) => {
                const {
                    setFieldValue,
                    handleChange,
                    values: formValues,
                } = props;
                const { phone_number, document, name } = formValues;

                const checkInitialValues = JSON.stringify(values);
                const checkValues = JSON.stringify(formValues);

                return (
                    <Form>
                        <div className={`container-input user active disabled`}>
                            <Field
                                name="name"
                                value={name}
                                placeholder={t("Nome")}
                            />
                        </div>
                        <ErrorMessage name="name" component="span" />

                        <div className={`container-input user active disabled`}>
                            <Field
                                placeholder="CNPJ"
                                name="document"
                                type="tel"
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    const document = maskCPF_CNPJ(
                                        e.target.value
                                    );

                                    if (document.length <= 18) {
                                        setFieldValue("document", document);
                                    }
                                }}
                                value={maskCPF_CNPJ(document)}
                            />
                        </div>
                        <ErrorMessage name="document" component="span" />
                        <div className={`container-input user`}>
                            <Field name="apelido" placeholder={t("apelido")} />
                        </div>
                        <ErrorMessage name="apelido" component="span" />

                        <div className={`container-input user active disabled`}>
                            <Field
                                name="nome_cpf"
                                placeholder={t("nome igual ao cpf")}
                            />
                        </div>
                        <ErrorMessage name="nome_cpf" component="span" />

                        <div className={`container-input contato`}>
                            <Field
                                name="email"
                                type="text"
                                placeholder="E-MAIL"
                            />
                        </div>
                        <ErrorMessage name="email" component="span" />
                        <div className="container-btns">
                            <ButtonEmail
                                className={`${
                                    user?.pessoa?.email_confirmed
                                        ? "active"
                                        : ""
                                }`}
                            >
                                {user?.pessoa?.email_confirmed
                                    ? t("VERIFICADO")
                                    : t("NÃO VERIFICADO")}
                            </ButtonEmail>

                            {!user?.pessoa?.email_confirmed && (
                                <button
                                    type="button"
                                    onClick={handleGenarateEmail}
                                    className="btn-verificar"
                                >
                                    ENVIAR NOVAMENTE
                                </button>
                            )}
                        </div>
                        <div className={`container-input celular`}>
                            <Mask
                                mask="(99) 99999-9999"
                                placeholder={t("celular")}
                                name="phone_number"
                                type="tel"
                                onChange={handleChange}
                                value={phone_number}
                            />
                        </div>
                        <ErrorMessage name="phone_number" component="span" />
                        <Button
                            isLoading={loading}
                            spinerColor="primary"
                            disabled={checkInitialValues === checkValues}
                            spiner="BallTriangle"
                        >
                            {t("Alterar")}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
}
