import { Text } from "alisson-application";
import { usePassos } from "../../../Hook/UsePassos";
import { useCupom } from "../../../Hook/user/useCupom";
import { useTranslation } from "../../../contexts/Localization";
import { Button } from "../ButtonSlim";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { cupom, cupomI, validadeCumpom } from "./config";
import { FormAddCupom } from "./styles";
import { useState } from "react";
import { useUser } from "../../../Hook/user/UseUser";

interface FomrAddCupomProps {}

export function FormAddCumpom(props: FomrAddCupomProps) {
    const { nextPasso, setDados } = usePassos();
    const { checkCupom } = useCupom();
    const { t } = useTranslation();
    const { user } = useUser();
    const [loading, setLoading] = useState(false);

    return (
        <Formik
            initialValues={cupom}
            isInitialValid={false}
            validationSchema={validadeCumpom}
            onSubmit={async (values, actions) => {
                setLoading(true); 
                const response = await checkCupom(values.cupom);
                const { data } = response;

                if (response.status !== 500) {
                    setDados({
                        cupom: values.cupom,
                        saldoAtual: user.saldo,
                        ...data,
                    });
                    nextPasso(2);
                }

                setLoading(false);
            }}
        >
            {(props: FormikProps<cupomI>) => {
                const { errors } = props;

                return (
                    <FormAddCupom className="container">
                        <Form>
                            <Text as="p">
                                {t(
                                    "Preencha o campo abaixo com o código do cupom que você ganhou."
                                )}
                            </Text>
                            <div
                                className={`container-input cupom ${
                                    errors.cupom ? "error-cupom" : ""
                                } ${loading ? "disabled" : ""}`}
                            >
                                <Field
                                    placeholder={t("Código do Cupom")}
                                    name="cupom"
                                />
                            </div>
                            <ErrorMessage name="cupom" component="span" />

                            {/* <div className="valor-cupom">
                                <Text
                                    as="span"
                                    color="primaryDark"
                                    fontSize="xss"
                                >
                                    {t("valor do cupom")}
                                </Text>
                                <Text
                                    as="span"
                                    color="primaryDark"
                                    fontSize="xss"
                                >
                                    R$ 300,00
                                </Text>
                            </div> */}

                            <Button
                                disabled={!props.isValid}
                                isLoading={loading}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                {t("Avançar")}
                            </Button>
                        </Form>
                    </FormAddCupom>
                );
            }}
        </Formik>
    );
}
