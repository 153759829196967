import styled from "styled-components";

export const Container = styled.div`
    border: 1px solid ${(props) => props.theme.colors.background};
    border-radius: 2px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    width: fit-content;
    img {
        display: block;
        margin-right: 8px;
    }
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primary};
        white-space: nowrap;
        margin-right: 8px;
    }

    strong {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        white-space: nowrap;
    }
`;
