import styled from "styled-components";
import imgLupa from "../../../../../assets/img/lupa.svg";

export const SearchCampaignContainer = styled.div`
    display: flex;
    gap: 20px;

    position: relative;
    margin-right: 16px;
    margin-bottom: 0px;

    input {
        height: 46px;
        background-color: transparent;
        margin: 0px !important;
        padding: 0 20px 0 40px;
        width: 100%;
        max-width: 500px;

        border: 1px solid ${(props) => props.theme.colors.secondarybright};
        border-radius: 4px;

        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: ${(props) => props.theme.colors.primaryDark} !important;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 14px;
        left: 15px;
        right: unset;
        background-color: ${(props) => props.theme.colors.secondary};
        mask: url(${imgLupa}) no-repeat;
        -webkit-mask: url(${imgLupa}) no-repeat;
        width: 19px;
        height: 17px;
        z-index: 20;
    }

    button {
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        width: max-content;
        padding: 0 20px;
        color: #ffffff;

        background-color: ${(props) => props.theme.colors.secondary};

        transition: 0.4s all;

        &:hover {
            opacity: 0.65;
        }

        &:active {
            transform: scale(1.1);
        }
    }
`;
