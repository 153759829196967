import { UseModal } from "../../../Hook/Usemodal";
import imgFile from "../../../assets/img/fileSucesso.svg";
import { Button } from "../Button";
import { Container } from "./styles";

export function DocumentoEnviado() {
    const { handleClose } = UseModal();
    return (
        <Container>
            <div className="container-img">
                <img src={imgFile} alt="" />
            </div>
            <div className="container-text">
                <h4>Documento enviado com sucesso!</h4>
                <br />
                <p>
                    Agradecemos pelo envio. Nossa equipe <br /> realizará a
                    avaliação e validação. Seu acesso YetzPay está liberado!
                </p>
            </div>
            <Button
                spinerColor="primary"
                spiner="BallTriangle"
                onClick={() => handleClose("sucessoVerificacaoDocumento")}
            >
                Ok, Entendi
            </Button>
        </Container>
    );
}
