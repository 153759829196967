import styled from "styled-components";
import { Form } from "formik";

export const Container = styled.div`
    overflow-y: scroll;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .container-tabs {
        width: 90%;
        margin: 0 auto;
        padding-top: 0;
        .container-loading {
            position: static;
        }
    }
`;

export const FormCpfStyles = styled(Form)`
    display: flex;
    flex-direction: column;
    padding: 35px 9px;

    p{
        line-height: 140%;
        margin-bottom: 30px;
    }


    .container-input {
        margin-bottom: 56px;
        input{
            height: 40px;
            padding-left: 40px;
        }
        
        &.user:before{
            left:14px;
        }
    }
`;

export const FormConfirmaUserStyles = styled(Form)`
    display: flex;
    flex-direction: column;
    padding: 35px 9px;

    .container-input.campanha:before{
        left:13px;
    }

    .container-input.valor:before{
        left:10px;
    }

    input{
        height: 40px !important;
        padding-left: 40px !important;
        text-transform: none !important;
    }

    button {
        margin-top: 56px;
    }
`;

export const StylesHeaderUser = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    .container-img {
        margin-right: 22px;
        img {
            width: 65px;
            height: 65px;
            border-radius: 50%;
        }
    }

    .container-text {
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.primary};
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }
`;

export const AlertaStyles = styled.div`
    .container-alerta {
        margin-top: 38px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 200px;
        .container-img {
            width: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 22px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            /* or 20px */

            /* Gray/02 */
            width: 208px;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }

    button {
        margin-bottom: 75px;
    }
`;



export const ContainerCardsTransferir = styled.div`
    padding:35px 9px;

    img {
        display: block;
        margin: 0 auto 21px;
        width:48;
    }

    h3{
        text-transform: none;
        font-size:20px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.secondary};
    }

    ul{
        margin-bottom:56px;
    }

`;
