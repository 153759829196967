import { InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";

interface InputContainerProps extends InputHTMLAttributes<HTMLInputElement> {
    mode?: "primary" | "secondary";
    error?: boolean;
}

export const InputContainer = styled.input<InputContainerProps>`
    max-width: 327px;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    border-radius: 100px;
    background: #fff;
    border: none;

    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;

    margin-bottom: 10px;

    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
    }

    ${({ disabled }) =>
        disabled &&
        css`
            background: #e3e3e3;
            color: ${(props) => props.theme.colors.textSubtle};
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
        `}

    ${({ mode }) =>
        mode === "secondary" &&
        css`
            border: 1px solid #aaa;
        `}

  ${({ error }) =>
        error &&
        css`
            color: var(--error-color);
            border-color: var(--error-color);
        `}
`;
