import styled from "styled-components";

export const Container = styled.div`
    width: 90%;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 35px;
    position: relative;
    margin: 0 auto;
    img {

    }
    h2 {
        margin:21px 0 30px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.secondary};
    }

    p {
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        margin: 0 auto;
        margin-bottom: 5px;
        padding: 0px 20px;
    }

    button{
        margin-top: 56px;
    }
`;
