import styled from "styled-components";

export const Container = styled.div`
    margin-top: 128px;
    padding-bottom: 73px;
    .container-check {
        width: 79px;
        margin: 0 auto;
        .container-img {
            width: 79px;
            height: 79px;
            background: #fdd2d2;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;
            img {
                width: 35px;
            }
        }
    }

    .container-text {
        margin-bottom: 38px;
        h3 {
            font-size: 24px;
            color: #2a2a2a;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 24px;
        }
        p {
            text-align: center;
            color: ${(props) => props.theme.colors.textSubtle};
            margin-bottom: 12px;
        }

        .container-form {
            width: 90%;
            margin: 0 auto;
            textarea {
                width: 100%;
                padding-top: 10px;
                padding-left: 10px;
                border-radius: 8px;
            }
        }
    }

    .container-btns {
        width: 234px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        button {
            &.confirmar {
                height: 60px;
                margin-bottom: 22px;
                background-color: ${(props) => props.theme.colors.failure};
            }
        }
    }
`;
