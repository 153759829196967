import styled from "styled-components";
import imgCheck from "../../../assets/img/check.svg";

export const SolicitarStyles = styled.div`
    p {
        width: 254px;
        margin: 0 auto;
        margin-bottom: 37px;
    }

    .valor-cupom {
        margin-top: 27px;
    }

    form {
        height: 200px;
        display: flex;
        flex-direction: column;
        .container-tipo {
            border: 1px solid ${(props) => props.theme.colors.secondarybright};
            border-radius: 32px;
            display: flex;
            align-items: center;
            padding: 0px 24px 0 12px;
            height: 40px;
            margin-bottom: 16px;
            cursor: pointer;
            position: relative;
            &.active {
                background: ${(props) => props.theme.colors.disabled};
                &:before {
                    opacity: 1;
                    transition: opacity 0.3s ease;
                    right: 10px;
                    top:50%;
                }
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 55%;
                right: 20px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${imgCheck}) no-repeat;
                width: 20px;
                height: 20px;
                z-index: 20;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
            img {
                margin-right: 8px;
            }
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                letter-spacing: 0.08em;
                text-transform: none;
                color: ${(props) => props.theme.colors.secondary};
                margin: 0px;
            }
        }
    }

    button {
        margin-top: 56px;
    }
`;

export const Container = styled.div`
    .container-tabs {
        padding-top: 34px;
        padding-bottom: 32px;
    }
`;
