import styled from "styled-components";

export const Container = styled.div`
    border-radius: 5px;
    background: ${(props) => props.theme.colors.background};
    padding: 17px 16px 26px 15px;
    margin-bottom: 8px;
    margin-right: 8px;
    &:last-child {
        margin-bottom: 0px;
    }
    .container-data {
        margin-bottom: 16px;
        h3 {
            color: ${(props) => props.theme.colors.primary};
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%;
            text-transform: uppercase;
        }
    }

    .container-pagamento {
        display: grid;
        /* grid-template-columns: 14px 1fr 90px; */
        grid-template-columns: 14px 95px 95px 90px;
        .container-img {
            padding-top: 3px;
        }

        .container-title {
            margin-left: 6px;
            grid-column: 2 / span 2;

            h4 {
                color: ${(props) => props.theme.colors.primary};
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%;
                text-transform: uppercase;
            }

            h5 {
                color: ${(props) => props.theme.colors.primary};
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
            }
        }

        .valor {
            grid-column: 4 / span 1;
            color: #ff8740;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            text-transform: uppercase;
            text-align: right;
        }

        .time {
            grid-column: 2 / span 1;
            margin-left: 6px;
            margin-top: 4px;
            color: ${(props) => props.theme.colors.primaryDark};
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
        }

        .comprovante {
            grid-column: 3 / span 2;
            color: #ff8740;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%;
            text-align: right;
            margin-top: auto;
        }
    }
`;
