import styled from "styled-components";
export const Container = styled.div`
    .container-itens {
        display: grid;
        grid-template-columns: 1fr 157px;
        grid-gap: 0 12px;
    }

    .isActive {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;

        small {
            font-size: 12px;
            color: ${(props) => props.theme.colors.black};
            margin-right: 8px;
            text-transform: uppercase;
            &.active {
                color: ${({ theme }) => theme.colors.secondary};
                font-weight: 700;
            }
            &.disabled {
                color: ${({ theme }) => theme.colors.failure};
                font-weight: 700;
            }
        }
    }
`;
