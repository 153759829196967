import styled from "styled-components";

export const ButtonGoBack = styled.button`
    width: 100%;
    height: 2.5rem;
    margin:0;
    
    font-family: Oxanium;
    font-size: 14px;
    font-weight: 400;
    color: #5e5f5f;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0;
    text-transform: none;
    transition: all 0.5s ease;

    &:hover {
        color: ${(props) => props.theme.colors.black};
    }

    &:disabled {
        color: ${(props) => props.theme.colors.textSubtle};
    }
`;
