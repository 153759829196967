import { HeaderModal } from "../Mobile/HeaderModal";
import { FormAlterarSenha } from "./form";
import { UseModal } from "../../Hook/Usemodal";
import { usePassos } from "../../Hook/UsePassos";
import { Container } from "./styles";
import { useEffect } from "react";
import { Validadar } from "../Mobile/Validar";
import { useUser } from "../../Hook/user/UseUser";

export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
        goBack: true,
    },
];


export function AlterarSenha() {
    const { updateUser } = useUser();
    const { state, setState} = UseModal();
    const { setPassos, passos, dados, setDados } = usePassos();

    useEffect(() => {
        setPassos(arrPassos);
    }, [setPassos]);


    async function handleValidar() {
        const response = await updateUser(dados);
        setDados({});
        setPassos(arrPassos)
        if (response.status === 200) {
            setState({
                ...state,
                alterarSenha: false,
                perfil: false,
                successGeral: {
                    active: true,
                    message: "",
                    title: "Senha Alterada<br/>com Sucesso!",
                },
            });
        }else{
            setState({
                ...state,
                alterarSenha: false,
                perfil: false,
                errorGeral: {
                    active: true,
                    message: "",
                    title: "Erro ao Alterar Senha",
                },
            });
        }
    }

    return (
        <Container className="container-tabs">
            <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                <HeaderModal
                    title="Alterar Senha"
                    handleClose={() => {
                        setState({
                            ...state,
                            alterarSenha: false,
                            perfil: true,
                        });
                    }}
                />

                <p>Preencha os campos abaixo para <br/>alterar a senha.</p>

                <FormAlterarSenha />
            </div>

            <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                {passos[1]?.active && 
                    <Validadar
                        handleClick={async () => handleValidar()}
                    />
                }
            </div>
        </Container>
    );
}