import axios, { AxiosResponse } from "axios";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { toast } from "react-toastify";
import { api } from "../../services";
import { base64Hash, getBaseUrl, getLocalStore } from "../../util/helper";
import { Pessoa } from "./UseLogin";

import { Variant } from "../types";

interface UseUserProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseUserData {
    user: UserProps;
    loading: boolean;
    codeWhatsapp: string;
    setCodeWhatsapp: (value: string) => void;

    validateUser: (type: Variant, number?: string) => Promise<void>;
    validateUserSemToken(type: Variant, values: ForgetPassword): Promise<any>;
    confirmCode: (code: string) => Promise<AxiosResponse>;
    confirmCodeSemToken(values: ConfirmCodeSemToken): Promise<any>;
    updateUser: (dados?: UpdateUser) => Promise<AxiosResponse>;
    setUpdateEnderecoUser: (dados: EnderecoUser) => void;
    updateEnderecoUser: EnderecoUser;
    updateEndereco: (dados: EnderecoUser) => Promise<AxiosResponse>;
    updateFotoUsuario: (dados: FormData) => Promise<AxiosResponse>;
    notificacoLida: (id: number) => Promise<AxiosResponse>;
    confirmEmailGenerate: () => Promise<AxiosResponse>;
    forgetPassword: (values: ForgetPassword) => Promise<AxiosResponse>;
    updatePassword(dados: UpdatePassword): Promise<any>;
    getCampanhas: () => Promise<Campanhas[] | undefined>;
    campanhas: Campanhas[];
    getUser: () => Promise<void>;
    novoSaldo(): Promise<void>;
    consultaUsuario(user: string): Promise<AxiosResponse<ConsultaUsuario>>;
    storeTransferBetweenWallets(
        dados: StoreTransferBetweenWalletsProps
    ): Promise<AxiosResponse<any>>;
}

interface ForgetPassword {
    document: string;
    birth_date: string;
    hash: string;
    type: Variant;
}

export interface UserProps {
    id: number;
    name: string;
    email: string;
    document: string;
    phone_number: string;
    status: number;
    register_status: number;
    all_campaigns_bill_payment_available: boolean | null;
    image: string;
    created_at: string | null;
    updated_at: string | null;
    saldo: string;
    email_confirmed: number;
    enderecos: Endereco[];
    pessoa: Pessoa;
    notificacoes: Notificacao[];
    first_login: number;
    has_virtual_card: boolean;
    read_recent_politicas: null | number;
    read_recent_termos: null | number;
    termos_uso_file: null | string;
    politicas_file: null | string;
    pending_document: null | boolean;
}

export interface Endereco {
    acg_address_id: null;
    bairro: string;
    cep: string;
    cidade: string;
    complemento: string;
    created_at: string;
    estado: string;
    id: number;
    nome: string;
    numero: string;
    pais: string;
    updated_at: string;
    user_id: number;
}

// export interface Pessoa {
//     aceita_email: number;
//     aceita_sms: number;
//     aceita_termos: number;
//     aceita_whats: number;
//     acg_people_id: null;
//     apelido: string;
//     created_at: string;
//     data_nascimento: string;
//     genero: string;
//     id: number;
//     nome_cpf: string;
//     nome_da_mae: string;
//     updated_at: string;
//     user_id: number;
// }

export interface Notificacao {
    id: number;
    status: string;
    data_disparo: string;
    describe: string;
    titulo: string;
    tipo: "NOTIFICACAO" | "POPUP";
    arte_desktop: string;
    arte_mobile: string;
    conteudo: string;
    acao: any;
    created_at: string;
}

interface UpdateUser {
    email?: string;
    nome_cpf?: string;
    nome_da_mae?: string;
    name?: string;
    phone_number?: string;
    apelido?: string;
    data_nascimento?: string;
    nome_igual_cpf?: string;
    genero?: string;
    password?: string;
    password_confirmation?: string;
    hash?: string;
}

interface EnderecoUser {
    cep?: string;
    nome?: string;
    numero?: string;
    bairro?: string;
    cidade?: string;
    estado?: string;
    pais?: string;
    complemento?: string;
}

export interface Campanhas {
    id: number;
    cliente_id: number;
    cliente_area_contato_id: number;
    nome: string;
    chave: string;
    regulamento: string;
    status: string;
    max_carga_credito_per_user: null | string;
    custom_fields: string;
    emissao: number;
    start_date_time: string;
    end_date_time: string;
    created_at: string;
    updated_at: string;
    credito_restante: string;
    cliente: Cliente;
    cliente_area_contato: ClienteAreaContato;
    produtos: Produtos[];
    force_activate_account_login: number;
    allow_transference_between_campain: number;
    allow_transference_between_users: number;
    allow_bill_payment: number;
    allow_bill_payment_available: number;
    allow_cupom: number;
}

interface Cliente {
    id: number;
    nome: string;
    razao_social: string;
    cnpj: string;
    created_at: string;
    updated_at: string;
    areas: Areas[];
}

interface Areas {
    id: number;
    cliente_id: number;
    nome: string;
    created_at: string;
    updated_at: string;
}

interface ClienteAreaContato {
    id: number;
    cliente_area_id: number;
    nome: string;
    telefone: string;
    celular: string;
    email: string;
    created_at: string;
    updated_at: string;
    areas: Areas;
}

interface Produtos {
    id: number;
    lim_quantidade: number;
    campanha_id: number;
    produto_id: number;
    created_at: string;
    updated_at: string;
    produto: Produto;
    taxa_cobranca: string;
}

export interface Produto {
    id: number;
    acg_produto_id: string;
    acg_comercial_origin_id: string;
    nome: string;
    descricao: string;
    descricao_complemento: string;
    tipo: string;
    lim_quantidade: number;
    lim_saque: string;
    lim_compras: string;
    taxa_cobranca: string;
    created_at: string;
    updated_at: string;
}

interface ConfirmCodeSemToken {
    code: string;
    hash: string;
}

interface UpdatePassword {
    document: string;
    password: string;
    hash: string;
}

interface ConsultaUsuario {
    document: string;
    id: number;
    name: string;
    image: string;
    register_status: number;
    campanhas: ConsultaUsuarioCampanhas[];
}

interface ConsultaUsuarioCampanhas {
    allow_transference_between_users: number;
    chave: string;
    end_date_time: string;
    id: number;
    nome: string;
    start_date_time: string;
}

interface StoreTransferBetweenWalletsProps {
    campanha_from_id: number;
    campanha_to_id: number;
    user_to_id: number;
    valor: number;
}

const UsePassosContext = createContext<UseUserData>({} as UseUserData);

export function UserProvider(props: UseUserProviderProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<UserProps>({} as UserProps);
    const [campanhas, setCampanhas] = useState<Campanhas[]>([]);
    const [codeWhatsapp, setCodeWhatsapp] = useState<string>("");
    const [updateEnderecoUser, setUpdateEnderecoUser] = useState<EnderecoUser>(
        {} as EnderecoUser
    );

    async function validateUser(type: Variant, number?: string) {
        try {
            await api.post(`usuario/generate-code`, {
                type: "whatsapp",
                phone_number: number,
            });
            toast.success("Código enviado com sucesso");
        } catch (error: any) {
            toast.error("Erro ao gerar código");
            return error?.response;
        }
    }

    async function validateUserSemToken(type: Variant, values: ForgetPassword) {
        const baseUrl = getBaseUrl();
        var url;

        if (baseUrl) {
            url =
                baseUrl[baseUrl?.length - 1] === "/" ? baseUrl : baseUrl + "/";
        }

        try {
            const response = await axios.post(`${url}forget-password`, {
                ...values,
                type,
            });
            toast.success("Código enviado com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao gerar código");
            return error?.response;
        }
    }

    async function forgetPassword(values: ForgetPassword) {
        const baseUrl = getBaseUrl();
        var url;

        if (baseUrl) {
            url =
                baseUrl[baseUrl?.length - 1] === "/" ? baseUrl : baseUrl + "/";
        }

        try {
            const response = await api.post(`${url}forget-password`, {
                ...values,
            });
            toast.success("Código enviado com sucesso");
            return response;
        } catch (error: any) {
            console.log(error.response.status);
            if (error.response.status === 403) {
                return error?.response;
            }
            toast.error(
                "Documento não encontrado ou data de nascimento inválida"
            );

            return error?.response;
        }
    }

    async function confirmCode(code: string) {
        try {
            const response = await api.post(`usuario/confirm-code`, {
                code: code,
            });
            return response;
        } catch (error: any) {
            toast.error("Erro ao gerar código");
            return error?.response;
        }
    }

    async function confirmCodeSemToken(values: any) {
        try {
            const response = await api.post(`confirm-code`, { ...values });
            return response;
        } catch (error: any) {
            toast.error("Erro ao gerar código");
            return error?.response;
        }
    }

    async function confirmEmailGenerate() {
        try {
            const response = await api.post(
                `usuario/generate-email-confirmation`
            );
            toast.success("E-mail enviado");
            return response;
        } catch (error: any) {
            toast.error("Erro ao enviar email");
            return error?.response;
        }
    }

    async function notificacoLida(id: number) {
        try {
            const response = await api.post(`recipiente/readed`, {
                recipiente_id: id,
            });

            await getUser();

            toast.success("Notificação marcada como lida");
            return response;
        } catch (error: any) {
            toast.error("Algo de errado aconteceu");
            return error?.response;
        }
    }

    async function novoSaldo() {
        const novoSaldo = user?.notificacoes?.find((item) =>
            item?.describe?.includes("NEW_BALANCE")
        );

        const id = novoSaldo?.id;

        if (id) {
            await notificacoLida(id);
        }
    }

    async function updateUser(dados?: UpdateUser) {
        const values = dados ? dados : updateEnderecoUser;
        try {
            const response = await api.post(
                `usuario/${getLocalStore().id}`,
                {
                    ...values,
                },
                {
                    headers: {
                        "x-token-auth2": `${base64Hash(codeWhatsapp)}`,
                    },
                }
            );
            await getUser();
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function updatePassword(dados: UpdatePassword) {
        try {
            const response = await api.post(
                `update-password`,
                { ...dados },
                {
                    headers: {
                        "x-token-auth2": `${base64Hash(codeWhatsapp)}`,
                    },
                }
            );
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function updateEndereco(dados: EnderecoUser) {
        try {
            const response = await api.put<UserProps>(
                `usuario/endereco/${user.enderecos[0].id}`,
                {
                    ...dados,
                },
                {
                    headers: {
                        "x-token-auth2": `${base64Hash(codeWhatsapp)}`,
                    },
                }
            );

            await getUser();
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function updateFotoUsuario(dados: FormData) {
        try {
            const response = await api.post(
                `usuario/${getLocalStore().id}`,
                dados
            );
            await getUser();
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function consultaUsuario(user: string) {
        try {
            const response = await api.post<AxiosResponse<ConsultaUsuario>>(
                "usuario/consult-document",
                {
                    documents: [user],
                }
            );

            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function storeTransferBetweenWallets(
        dados: StoreTransferBetweenWalletsProps
    ) {
        try {
            const response = await api.post(
                `transferencia/usuario`,
                {
                    ...dados,
                },
                {
                    headers: {
                        "x-token-auth2": `${base64Hash(codeWhatsapp)}`,
                    },
                }
            );

            await getUser();

            toast.success("Sucesso!");
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    const getCampanhas = useCallback(async () => {
        try {
            const response = await api.get<Campanhas[]>(`usuario/campanhas`);
            setCampanhas(response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            toast.error("Erro ao carregar dados do usuário");
        }
    }, []);

    const getUser = useCallback(async () => {
        const { campanha_user } = getLocalStore();

        try {
            setLoading(true);

            const { data } = await api.get<UserProps>(
                `usuario/me?campanhas[0]=${campanha_user?.id}`
            );

            setLoading(false);

            setUser(data);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao carregar dados do usuário");
        }
    }, []);

    useEffect(() => {
        const rota = window.location.pathname;
        const regex = [
            "/sistema",
            "/sistema/",
            "/precisa-ajuda",
            "/id-wall",
            "/sistema/authenticator",
        ];
        if (rota !== "/" && !regex.includes(rota)) {
            getUser();
        }

        if (/campanhas/.test(rota)) {
            getCampanhas();
        }
    }, [getUser, getCampanhas]);

    return (
        <UsePassosContext.Provider
            value={{
                loading,
                user,
                codeWhatsapp,
                setCodeWhatsapp,
                validateUser,
                validateUserSemToken,
                confirmCode,
                updatePassword,
                confirmCodeSemToken,
                confirmEmailGenerate,
                forgetPassword,
                getUser,
                updateUser,
                setUpdateEnderecoUser,
                updateEnderecoUser,
                updateEndereco,
                updateFotoUsuario,
                notificacoLida,
                novoSaldo,
                getCampanhas,
                campanhas,
                consultaUsuario,
                storeTransferBetweenWallets,
            }}
        >
            {children}
        </UsePassosContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UsePassosContext);

    return context;
}
