import { Container } from "./styles";
import { Button } from "../Mobile/Button";
import ImgError from "../../assets/img/modal-error.svg";
import { Text } from "alisson-application";
import { UseModal } from "../../Hook/Usemodal";

export function CpfInvalido() {
    const { closeAllModais } = UseModal();
    return (
        <Container>
            <div className="container">
                <img className="img-close" src={ImgError} alt="" />

                <Text
                    fontSize="lg"
                    textAlign="center"
                    color="failure"
                    fontWeight="bold"
                >
                    OPS
                </Text>

                <Text
                    fontSize="xs"
                    textAlign="center"
                    color="primary"
                    mb="39px"
                >
                    O Pagamento de Contas só pode ser
                    <br /> realizado quando o pagador possuir
                    <br /> o mesmo CPF
                </Text>

                <Button
                    onClick={() => {
                        closeAllModais();
                    }}
                >
                    Ok, Entendi
                </Button>
            </div>
        </Container>
    );
}
