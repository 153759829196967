import { useEffect, useState } from "react";
import { UseModal } from "../../Hook/Usemodal";
import { Cartoes, UseCartoes } from "../../Hook/user/UseCartaoes";
import { api } from "../../services";
import { getLocalStore } from "../../util/helper";
import { Button } from "../Mobile/ButtonSlim";
import { Card } from "./card";
import { Container } from "./styles";
import { CountDownMemo } from "../Countdown";
import { Box } from "alisson-application";
import { useUser } from "../../Hook/user/UseUser";

export function AtivarCartaoAutomatico() {
    const { cartoes } = UseCartoes();
    const { getUser } = useUser();
    const { closeAllModais, state, setState } = UseModal();
    const { campanha_user } = getLocalStore();
    const [timer, setTimer] = useState(false);
    const [loading, setLoading] = useState(false);
    const cartao = cartoes.find(
        (cartao) => cartao.status === "ATIVANDO"
    ) as Cartoes;
    const [status, setStatus] = useState("ATIVANDO");

    async function updateCartao() {
        setLoading(true);
        await getUser();
        setLoading(false);
        closeAllModais();
    }

    async function getCartao() {
        setTimer(true);
        try {
            setLoading(true);
            const { data } = await api.get<Cartoes[]>(
                `/cartao?campanhas[0]=${campanha_user?.id}`
            );

            const currentCard = data.find(
                (card) => card.id === cartao.id
            ) as Cartoes;

            if (currentCard?.status === "ATIVO") {
                setTimer(false);
                setStatus(currentCard?.status);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        if (
            state.ativarCartaoAvulsoSubHeader ||
            state.ativarCartaoAvulso ||
            state.ativarCartao
        ) {
            setState({
                ...state,
                ativarCartaoAvulsoSubHeader: false,
                ativarCartaoAvulso: false,
                ativarCartao: false,
            });
        }
    }, [setState, state]);

    return (
        <Container>
            <div className="container-title">
                <h1>ativar cartão</h1>
            </div>

            <Card {...cartao} status={status} />

            <div className="container-description">
                {status === "ATIVANDO" ? (
                    <p>
                        Seu cartão poder levar alguns minutos para ser ativado.
                        Atualize a página ou <br /> volte em breve.
                    </p>
                ) : (
                    <p>Cartão Ativado com sucesso!</p>
                )}
            </div>

            {timer && (
                <Box my="16px">
                    <CountDownMemo setTimer={setTimer} />
                </Box>
            )}

            {status === "ATIVANDO" ? (
                <Button
                    onClick={getCartao}
                    disabled={timer}
                    isLoading={loading}
                >
                    Atualizar
                </Button>
            ) : (
                <Button
                    isLoading={loading}
                    disabled={timer}
                    onClick={updateCartao}
                >
                    Ok
                </Button>
            )}
        </Container>
    );
}
