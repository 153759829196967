import { Box, Text } from "alisson-application";
import { UseModal } from "../../../Hook/Usemodal";
import imgCartao from "../../../assets/img/cartao.svg";
import imgEntrega from "../../../assets/img/entrega.svg";
import { SolicitarStyles } from "./styles";
import { usePassos } from "../../../Hook/UsePassos";

export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
        goBack: true,
    },
];

interface ChangeProps {
    setToggle: (value: boolean) => void;
}

export function Change(props: ChangeProps) {
    const { setState, state } = UseModal();
    const { setPassos } = usePassos();
    return (
        <SolicitarStyles className="container">
            <Text as="p" textAlign="center">
                Selecione uma das opções abaixo:
            </Text>

            <Box className="options">
                <div
                    onClick={() => {
                        props.setToggle(false);
                        setPassos(arrPassos);
                    }}
                    className={`container-tipo`}
                >
                    <img className="img-active" src={imgCartao} alt="" />
                    <p>Ativar cartão</p>
                </div>
                <div
                    onClick={() => {
                        setState({
                            ...state,
                            ativarCartaoAvulso: false,
                            entrega: true,
                        });
                    }}
                    className={`container-tipo`}
                >
                    <img className="img-status" src={imgEntrega} alt="" />
                    <p>Ver Status da Entrega</p>
                </div>
            </Box>
        </SolicitarStyles>
    );
}
