import { Flex } from "alisson-application";
import styled from "styled-components";
import imgCheck from "../../../assets/img/check.svg";

export const Container = styled.div`
    overflow-y: scroll !important;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
    p {
        text-align: center;
        padding-top: 19px;
        padding-bottom: 26px;
        width: 296px;
        margin: 0 auto;
    }
`;

export const FormStyles = styled.div`
    margin: 0 auto;
    width: 90%;
    margin-bottom: 35px;

    small {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.secondary};
        font-weight: bold;
        margin: 40px 0px 10px;
        padding-left: 30px;
        position: relative;
        display: block;
        font-weight: bold;
        color: ${(props) => props.theme.colors.secondary};
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background: ${(props) => props.theme.colors.primaryDark};
        }
    }

    button {
        &.open-modal-cvv {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            text-align: left;
            color: ${(props) => props.theme.colors.secondary};
            width: 100%;
            margin-bottom: 68px;
            padding-left: 30px;
        }
    }

    form{
        input{
            height: 40px;
            padding-left: 40px;
            font-size: 14px;
            text-transform: none;
        }
        
        .container-input.chave-cartao:before,
        .container-input.cartao-ativar:before {
            left: 12px;
        }

        .mt-auto{
            margin-top:56px;
        }
    }
`;

export const ModalStyles = styled.div`
    padding-bottom: 22px;
    padding-top: 69px;
    position: relative;
    p {
        text-align: center;
        width: 288px;
        margin: 0 auto;
        margin-bottom: 36px;
    }

    img {
        &.cartao {
            width: 288px;
            margin: 0 auto;
            display: block;
        }

        &.icone-cartao-ativar {
            position: absolute;
            top: -90px;
            left: 50%;
            transform: translateX(-50%);
            pointer-events: none;
        }
    }
`;

export const FinalizarStyles = styled(Flex)`
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-bottom: 35px;
    img {
        width: 48px;
    }

    h3 {
        margin-top: 21px;
        margin-bottom: 21px;
        font-size: 20px;
        font-weight: 700;
        text-transform:none;
        color: ${(props) => props.theme.colors.secondary};
        text-align: center;
        line-height:140%
    }

    p {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0 auto;
        margin-bottom: 56px;
    }
`;

export const SolicitarStyles = styled.div`
    padding-bottom: 29px;
    p {
        width: 254px;
        margin: 0 auto;
    }

    .valor-cupom {
        margin-top: 27px;
    }

    .options {
        display: flex;
        flex-direction: column;
        .container-tipo {
            position: relative;
            display: flex;
            align-items: center;
            border: 1px solid ${(props) => props.theme.colors.secondarybright};
            border-radius: 32px;
            padding: 0px 24px 0 14px;
            height: 40px;
            margin-bottom: 16px;
            cursor: pointer;
            position: relative;
            &.active {
                background: ${(props) => props.theme.colors.disabled};
                &:before {
                    opacity: 1;
                    transition: opacity 0.3s ease;
                }
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 55%;
                right: 20px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${imgCheck}) no-repeat;
                width: 20px;
                height: 20px;
                z-index: 20;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
            .img-status {
                margin-right: 8px;
            }

            .img-active {
                margin-right: 16px;
            }
            p {
                text-align: left;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: ${(props) => props.theme.colors.secondary};
                margin: 0px;
                padding: 0px;
            }
        }
    }

    button {
        margin-top: auto;
    }
`;
