import { Flex } from "alisson-application";
import styled from "styled-components";
import imgGps from "../../../assets/img/gps.svg";
import Yimg from "../../../assets/img/Y.svg";

export const Container = styled.div`
    .container-tabs {
        padding-top: 56px;
        padding-bottom: 32px;
    }
`;

export const EnderecoStyles = styled.div`
    h3 {
        text-align: center;
        width: 290px;
        margin: 0 auto;
        font-size: 18px;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 30px;
    }

    .add-endereco {
        position: relative;
        font-weight: 700;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 27px;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.colors.secondary};
            -webkit-mask: url(${imgGps}) no-repeat;
            width: 24px;
            height: 24px;
            z-index: 20;
            transition: background-color 0.5s;
        }
    }

    .card-endereco {
        border: 1px solid #e1e2e3;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 19px 19px 21px 27px;
        display: flex;
        align-items: start;
        position: relative;
        &.active {
            border: 1px solid ${(props) => props.theme.colors.secondary};
            transition: all 0.5s ease;
            &:before {
                background-color: ${(props) => props.theme.colors.secondary};
            }
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 21px;
            background-color: ${(props) => props.theme.colors.primaryBright};
            -webkit-mask: url(${imgGps}) no-repeat;
            width: 24px;
            height: 24px;
            z-index: 20;
            transition: background-color 0.5s;
        }

        .container-text {
            padding-left: 40px;
        }

        .check-box {
            margin-left: auto;
        }
    }
`;

export const ConfereDados = styled.div`
    h3 {
        text-align: center;
    }

    .container-dados {
        display: flex;
        align-items: center;
        background: ${(props) => props.theme.colors.white};

        border: 1px solid #e1e2e3;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 19px 0px 23px 21px;
        .container-text-detalhe {
            margin-left: 13px;
            h4 {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primary};
                margin-bottom: 8px;
            }

            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: ${(props) => props.theme.colors.primaryDark};
            }
        }
    }

    ul {
        margin-top: 23px;
        margin-bottom: 35px;
        li {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e1e2e3;
            &.endereco {
                min-height: 48px;
                height: auto;
                padding-top: 14px;
                .container-img {
                    align-self: start;
                    width: 24px;
                    height: 24px;
                    position: relative;
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0px;
                        background-color: ${(props) =>
                            props.theme.colors.secondary};
                        -webkit-mask: url(${imgGps}) no-repeat;
                        width: 24px;
                        height: 24px;
                        z-index: 20;
                        transition: background-color 0.5s;
                    }
                }
            }
            &:last-child {
                border-bottom: none;
            }
            .container-img {
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 16px;
            }
        }
    }
`;

export const FinalizarStyles = styled(Flex)`
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-bottom: 35px;
    
    img {
        width: 48px;
        margin: 0 auto 21px;
    }

    h3 {
        margin-bottom: 26px;
        font-size:20px;
        font-weight:700;
        color: ${(props) => props.theme.colors.secondary};
    }

    p {
        width: 296px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 56px;
        font-size: 14px;
        color:${(props) => props.theme.colors.textSubtle};
    }
`;

interface CardProps {
    background: string;
}

export const CardStyles = styled.div<CardProps>`
    background: ${(props) => props.background};
    border-radius: 2px;
    padding: 7.26px 3px 2.89px 2px;
    height: 43px;
    width: 34px;
    position: relative;
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: ${(props) => props.theme.colors.white};
        -webkit-mask: url(${Yimg}) no-repeat;
        width: 34px;
        height: 43px;
        z-index: 20;
        -webkit-mask-position: 0px 0px;
        -webkit-mask-size: 34px;
        transition: background-color 0.5s;
    }
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 3.63107px;
        line-height: 5px;
        color: ${(props) => props.theme.colors.white};
        margin-bottom: 12px;
    }

    span {
        font-style: normal;
        font-weight: 600;
        font-size: 2.9px;
        line-height: 100%;
        letter-spacing: 0.06em;
        color: ${(props) => props.theme.colors.white};
        margin: 0px;
        display: block;
        margin-bottom: 7.5px;
        white-space: nowrap;
    }

    .container-img {
        height: 4px;
        img {
            height: 100%;
            width: 7px;
            display: block;
            margin-left: auto;
        }
    }

    /* 

    .card {
        
    } */
`;
