import styled from "styled-components";

export const Container = styled.div`
    padding: 30px;
    background: ${(props) => props.theme.colors.background};
    border-radius: 16px;

    .item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e1e2e3;
        padding: 16px 0px;
        &.list {
            flex-direction: column;
            align-items: flex-start;
        }
        &:last-child {
            border: none;
        }
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 16px;
        }
        ul {
            li {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primary};
                list-style: disc;
                margin-left: 16px;
                margin-bottom: 8px;
                text-transform: uppercase;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
        img {
            display: block;
            margin-right: 8px;
        }
    }
`;
