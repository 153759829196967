import styled from "styled-components";

export const PasswordStepContainer = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
    padding: 60px 30px;
`;

export const PasswordContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 330px;
`;

export const Title = styled.h1`
    font-family: Oxanium;
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    text-align: center;
    color: ${(props) => props.theme.colors.secondary};

    text-transform: uppercase;
    text-align: center;
`;

export const Description = styled.p`
    font-family: Oxanium;
    font-size: 14px;
    font-weight: 400;

    color: ${(props) => props.theme.colors.black};

    margin: 20px 0 30px;
    text-align: center;

    b {
        text-transform: uppercase;
    }
`;

export const DisabledInput = styled.input`
    max-width: 327px;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    border-radius: 100px;
    background: #e3e3e3;
    color: ${(props) => props.theme.colors.textSubtle};
    border: none;

    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    text-align: center;

    margin-bottom: 10px;

    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
    }
`;

export const LoaderContainer = styled.div`
    width: 100%;
    height: 129px;
`;
