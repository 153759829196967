import { HeaderModal } from "../Mobile/HeaderModal";
import { UseModal } from "../../Hook/Usemodal";
import { FormAlterarEndereco } from "./form";
import { Container } from "./styles";

export function AlterarEndereco() {
    const { state, setState } = UseModal();

    return (
        <Container>
            <HeaderModal
                title="Alterar Endereço"
                handleClose={() => {
                    setState({
                        ...state,
                        alterarEndereco: false,
                        perfil: true,
                    });
                }}
            />
            <p> </p>
            <FormAlterarEndereco />
        </Container>
    );
}
