import styled from "styled-components";

export const LinkStyled = styled.button`
    background-color: ${({ theme }) => theme.colors.white};
    padding: 14px 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 119px;
    border-radius: 8px;
    height: 129px;
    margin-right: 8px;
    &.branco {
        background-color: ${({ theme }) => theme.colors.secondary};
        p {
            color: ${({ theme }) => theme.colors.white};
        }
    }
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.secondary};
        margin-top: 16px;
        line-height: 14px;
    }
`;

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.background};
    height: 166px;
    display: flex;
    align-items: center;
    .container {
        display: flex;
        align-items: center;
        .container-user {
            img {
                width: 59px;
                height: 59px;
                border-radius: 50%;
            }
            .container-text {
                p {
                    color: ${({ theme }) => theme.colors.primaryDark};
                    font-size: ${({ theme }) => theme.fonts.xss};
                    margin-bottom: -5px;
                }
            }
        }

        .container-btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 84px;
            margin-left: auto;
            margin-right: 21px;
        }

        .container-saldo {
            background: ${({ theme }) => theme.colors.white};
            border-radius: 8px;
            padding: 23px 0px 0px;
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            position: relative;
            &.active {
                .dinheiro {
                    &:before {
                        animation: shimmer 1.2s ease-in-out infinite;
                    }
                }
            }
            .container-olho {
                margin-left: auto;
                position: absolute;
                width: 28px;
                height: 28px;
                top: -1.51px;
                right: 10px;
                &.hide {
                    &:before {
                        transform: translate(-50%, -50%) rotate(-45deg) scale(0);
                        transition: transform 0.5s;
                    }
                }
                &:before {
                    content: "";
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    background-color: ${(props) =>
                        props.theme.colors.secondary};
                    border-radius: 8px;
                    z-index: 1;
                    transform: translate(-50%, -50%) rotate(-45deg) scale(1);
                    transition: transform 0.5s;
                }

                img {
                    width: 28px;
                }
            }

            .dinheiro {
                position: relative;
                overflow: hidden;
                width: 115px;
                height: 24px;
                margin-right: 4px;
                margin-left: 4px;
                line-height: normal;
                margin-top: 1px;
                cursor: pointer;
                &.show {
                    &::after {
                        opacity: 0;
                        transition: all 0.5s;
                    }
                    &::before {
                        opacity: 0;
                        transition: all 0.5s;
                    }
                }
                &:after {
                    background: #eeeeee;
                }
                &:before {
                    background-image: linear-gradient(
                        90deg,
                        #eeeeee 12.24%,
                        #fff 107.14%
                    );
                }

                &:after {
                    content: "";
                    position: absolute;
                    color: grey;
                    display: inline-block;
                    background-repeat: no-repeat;
                    font-size: 50px;
                    max-width: 200px;
                    top: 50%;
                    left: 0px;
                    transform: translateY(-50%);
                    width: 100%;
                    height: 24px;
                    opacity: 1;
                    transition: opacity 0.5s;
                }
                &:before {
                    content: "";
                    position: absolute;
                    color: grey;
                    display: inline-block;
                    background-image: linear-gradient(
                        90deg,
                        #eeeeee 12.24%,
                        #fff 107.14%
                    );
                    background-repeat: no-repeat;
                    font-size: 50px;
                    max-width: 200px;
                    top: 0px;
                    left: 0px;
                    transform: translateX(-100%);
                    width: 100%;
                    height: 24px;
                    z-index: 99;
                    opacity: 1;
                    transition: opacity 0.5s;
                }
            }

            @keyframes shimmer {
                100% {
                    transform: translateX(100%);
                }
            }

            button {
                margin-bottom: 0px;
                display: block;
                border-radius: 8px 8px 5px 5px;
                width: 101%;
            }
            span {
                padding-left: 36px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${({ theme }) => theme.colors.primaryDark};
                display: block;
            }
        }
    }
`;
