import { Text, Flex, Box } from "alisson-application";
import { useHistory } from "react-router-dom";
import { getLocalStore } from "../../util/helper";
import { HeaderModal } from "../Mobile/HeaderModal";
import { useTranslation } from "../../contexts/Localization";
import { useUser } from "../../Hook/user/UseUser";
import { UseModal } from "../../Hook/Usemodal";
import { FormPerfil } from "./form";
import { FormPerfilCNPJ } from "./formCNPJ";
import { Endereco } from "./endereco";
import { Crop } from "../Crop";
import { Validadar } from "../Mobile/Validar";
import imgUser from "../../assets/img/user.png";
import imgCadeado from "../../assets/img/cadeado.svg";
import imgDir from "../../assets/img/seta-dir-verde.svg";
import imgCamera from "../../assets/img/inserir-foto.svg";

import { Container } from "./styles";
import { usePassos } from "../../Hook/UsePassos";
import { useEffect} from "react";
import { usePerfil } from "../../Hook/user/usePerfil";

export const stepsProfile = [
    {
        passo: 1,
        active: false,
        completed: true,
    },
    {
        passo: 2,
        active: true,
        completed: true,
    },
];

export function Perfil() {
    const history = useHistory();
    const { user, updateUser } = useUser();
    const { dataSemsible, setDataSensible, values } = usePerfil();
    const { setState, state } = UseModal();
    const { setDados } = usePassos();
    const { t } = useTranslation();
 
    // const hasPhoneNumbersModified = values.phone_number !== user.phone_number;
    const width = window.screen.width;

    function handleClose() {
        width < 480
            ? history.push("/dashboard")
            : setState({ ...state, perfil: false, menu: true });

        setDataSensible(false);
    }

    useEffect(() => {
        setDados({});
    }, [setDados]);

    return (
        <>
            <HeaderModal title="Meu Perfil" handleClose={() => handleClose()} />

            <div className="container-tabs remove-pt">
                <div className={`tab ${!dataSemsible ? "active" : ""}`}>
                    {!dataSemsible ? (
                        <Container>
                            <div className="container-header">
                                {user?.image ? (
                                    <Crop>
                                        <img
                                            src={
                                                user?.image
                                                    ? user?.image
                                                    : imgUser
                                            }
                                            alt=""
                                        />
                                    </Crop>
                                ) : (
                                    <Flex
                                        flexDirection="column"
                                        className="crop"
                                    >
                                        <img
                                            src={
                                                user?.image
                                                    ? user?.image
                                                    : imgUser
                                            }
                                            alt=""
                                        />
                                        <Crop>
                                            <div className="container-inserir-foto">
                                                <img src={imgCamera} alt="" />
                                                <Text as="p">
                                                    INSERIR <br /> FOTO
                                                </Text>
                                            </div>
                                        </Crop>
                                    </Flex>
                                )}

                                <div className="container-text">
                                    <Text as="h3">{t("Olá")}</Text>
                                    <Text as="h2" textTransform="uppercase">
                                        {user?.pessoa?.apelido}
                                    </Text>
                                </div>

                                {user?.image && (
                                    <div className="container-add-image">
                                        <Crop>
                                            <img src={imgCamera} alt="" />
                                        </Crop>
                                    </div>
                                )}
                            </div>

                            <button
                                onClick={() => {
                                    setState({
                                        ...state,
                                        perfil: false,
                                        alterarSenha: true,
                                    });
                                }}
                                className="container-senha"
                            >
                                <img src={imgCadeado} alt="" />
                                <Text as="p">{t("Alterar senha")}</Text>
                                <img className="seta" src={imgDir} alt="" />
                            </button>

                            {getLocalStore().pessoa.tipo === "juridica" ? (
                                <FormPerfilCNPJ />
                            ) : (
                                <FormPerfil />
                            )}

                            {getLocalStore().pessoa.tipo === "fisica" && (
                                <Endereco {...user} />
                            )}
                        </Container>
                    ) : (
                        <></>
                    )}
                </div>

                <div className={`tab ${dataSemsible ? "active" : ""}`}>
                    <Box pt="32px">
                        {dataSemsible ? (
                            <Validadar
                                handleClick={async () => {
                                    const response = await updateUser(values);
                                    if (response?.statusText === "OK")  {
                                        setDataSensible(false);
                                        setState({
                                            ...state,
                                            perfil: false,
                                            successGeral: {
                                                active: true,
                                                message: "",
                                                title: "Dado Atualizado<br/> com Sucesso!",
                                            },
                                        });
                                    }else{
                                        setState({
                                            ...state,
                                            perfil: false,
                                            errorGeral: {
                                                active: true,
                                                message: "",
                                                title: "Erro ao Atualizar Dado",
                                            },
                                        });
                                    }
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </Box>
                </div>
            </div>
        </>
    );
}
