import styled from "styled-components";
import { Container as SimpleAccordion } from "../../components/Accordion/styles";
import imgSetaEsq from "../../assets/img/seta-esq-verde.svg";

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
    padding-top: 100px;
    .container-fale-conosco {
        width: 1124px;
        margin: 0 auto;
    }
    span {
        font-style: normal;
        font-weight: 700;
        font-size: 20.5625px;
        color: #ffffff;
        display: block;
        margin-left: 20px;
    }

    button {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        position: relative;
        &.contato {
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 55%;
            left: -8px;
            transform: translate(-50%, -50%);
            background-color: ${(props) => props.theme.colors.white};
            -webkit-mask: url(${imgSetaEsq}) no-repeat;
            width: 8px;
            height: 14px;
            z-index: 20;
        }
    }

    .container-img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 219px;
        }
    }

    .container-header {
        display: flex;
        align-items: center;
        margin-bottom: 38px;
        button {
            margin-left: auto;
        }
    }

    .container-title {
        width: fit-content;
        margin-right: 100px;
        display: flex;
        align-items: center;
        display: flex;
        position: relative;
        img {
            width: 72px;
            height: 74px;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 31px;
            right: -25%;

            background-color: ${(props) => props.theme.colors.white};
        }
    }

    .container-content {
        background-color: ${(props) => props.theme.colors.white};
        border-radius: 10px;
        padding: 60px 68px 81px 68px;
        ${SimpleAccordion} {
            padding: 15px 21px 14px 28px;
            box-shadow: none;
            border: 1px solid #9cabb8;
            border-radius: 15px;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0px;
            }
            &:before {
                display: none;
            }

            .MuiButtonBase-root {
                padding: 0px;
            }
            .MuiAccordionDetails-root {
                padding: 0px;
            }
            .MuiAccordionSummary-content {
                margin: 0px;
                div {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    color: ${(props) => props.theme.colors.secondary};
                    padding-right: 62px;
                }
            }
        }
        h2 {
            font-weight: 900;
            font-size: 36px;
            line-height: 29px;
            color: #ffffff;
            text-align: center;
            margin-bottom: 32px;
        }

        .item {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0px;
                padding-bottom: 40px;
            }
            h5 {
                font-weight: 900;
                font-size: 21px;
                line-height: 29px;
                color: #ffffff;
                margin-bottom: 10px;
            }

            p {
                font-weight: normal;
                font-size: 18px;
                line-height: 26px;
                color: #ffffff;
            }
        }
    }

    .container-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 58px;
        padding-bottom: 80px;
        margin-left: auto;
        .fale-conosco {
            display: flex;
            align-items: center;
            margin-left: auto;
            span {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 41px;
                color: #ffffff;
                display: block;
                margin-right: 13px;
                margin-left: 0px;
            }
        }
        button {
            &.btn-voltar {
                margin-left: 156px;
            }
            &.contato {
                background: #f17e21;
                border-radius: 44px;
                font-style: normal;
                font-weight: 900;
                font-size: 16px;
                line-height: 41px;
                text-transform: uppercase;
                color: #ffffff;
                width: 249px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:before {
                    display: none;
                }
                img {
                    margin-right: 9px;
                }
            }

            span {
                display: block;
                line-height: normal;
            }
        }
    }

    .footer {
        background-color: ${(props) => props.theme.colors.white};
    }

    @media (max-width: 1366px) {
        .container-fale-conosco {
            width: 90%;
        }
    }

    @media (max-width: 768px) {
        .container-btns {
            justify-content: space-between;
            .fale-conosco {
                margin: 0px;
            }
            button {
            }
        }
    }

    @media (max-width: 480px) {
        padding-top: 32px;
        .container-content {
            padding: 30px 18px 30px 18px;
            ${SimpleAccordion} {
                .MuiAccordionSummary-content {
                    div {
                        padding-right: 35px;
                    }
                }
            }
        }

        .container-btns {
            width: 100%;
            flex-direction: column-reverse;
            margin-top: 8px;
            padding-bottom: 37px;
            .fale-conosco {
                display: flex;
                flex-direction: column;
                margin-top: 22px;
            }
            button {
                &.btn-voltar {
                    margin-left: auto;
                }
            }
        }

        .container-title {
            margin-right: 0px;
            position: static;
            &:before {
                left: 45%;
            }
            img {
                width: 43px;
            }
            span {
                font-size: 9.52741px;
                margin-left: 14px;
            }
        }

        .container-header {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 27px;
            grid-gap: 27px 0px;
            .btn-voltar {
                grid-column: span 2;
                order: 1;
            }

            .container-title {
                order: 2;
            }

            .container-img {
                order: 3;
            }
        }

        .container-img {
            width: 146px;
            margin-left: auto;
            img {
                width: 100%;
            }
        }
        .container-fale-conosco {
            h2 {
                font-size: 24px;
            }
        }
    }
`;

export const JogoStyles = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    .quadrante {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border: 1px solid red;
        align-items: flex-end;
        justify-content: baseline;
        height: 300px;
        span {
            display: block;
            font-size: 32px;
            font-weight: bold;
            margin-bottom: auto;
            &:nth-child(2n) {
                text-align: right;
            }

            &:nth-child(3) {
                margin-bottom: 0px;
            }
            &:nth-child(4) {
                margin-bottom: 0px;
            }
        }
    }
`;

export const RetornoStyles = styled.div`
    padding: 32px;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.secondary};
    h1 {
        font-weight: bold;
        font-size: 26px;
        line-height: 30px;
        margin: 60px 0 30px;
        color: ${(props) => props.theme.colors.white};
    }
    p {
        font-weight: bold;
        font-size: 16px;
        line-height: 29px;
        color: ${(props) => props.theme.colors.white};
        margin-bottom: 32px;
    }

    button {
        display: flex;
        align-items: center;
        &.btn-voltar {
            background-image: url("https://www.yetzcards.com.br/img/novo-login/botao-branco.svg");
            display: flex;
            height: 48px;
            width: 294px;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.secondary};
            font-weight: bold;
        }
        span {
            display: block;
            line-height: normal;
        }
    }
`;
