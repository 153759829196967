import styled from "styled-components";
import { Container as Card } from "../../Mobile/CartaoFisico/styles";
import { CardStyles as Cartoes } from "../../Mobile/Card/styles";
import { Container as CardCupom } from "../../../components/Mobile/AdicionarCupom/styles";
import { Container as CardSaldo } from "../../../components/Mobile/SolicitarResgate/styles";
import { Container as CardMovimentacao } from "../../../components/Mobile/Movimentacao/styles";
import Yimg from "../../../assets/img/Y.svg";

export const Container = styled.div`
    .overview {
        padding-top: 39px;
        padding-bottom: 39px;
    }
`;

interface CardProps {
    background: string;
}

export const CardStyles = styled.div<CardProps>`
    background: ${(props) => props.background};
    box-shadow: 0px 20px 40px rgba(138, 230, 130, 0.2);
    border-radius: 8px;
    height: 145px;
    width: 103px;
    padding-left: 9px;
    padding-right: 5px;
    padding-top: 22px;
    padding-bottom: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(${Yimg});
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 10;
    }
    p {
        &.type {
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 14px;
            color: ${(props) => props.theme.colors.white};
            margin-bottom: 9px;
        }
    }
    .saldo {
        border: 0.658986px solid ${(props) => props.theme.colors.white};
        height: 35px;
        border-radius: 2.63594px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        div {
            display: flex;
            align-items: start;
            height: 16px;
            padding-left: 5px;
        }
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 5.85585px;
            line-height: 100%;
            display: block;
            padding-top: 1px;

            color: ${(props) => props.theme.colors.white};
        }

        p {
            font-style: normal;
            font-weight: 600;
            font-size: ${(props) => props.theme.fonts.md};
            line-height: 100%;
            color: ${(props) => props.theme.colors.white};
        }
    }

    .bandeira {
        text-align: right;
    }

    .number-card {
        margin-top: auto;
        margin-bottom: 15px;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 0.531rem;
            line-height: 100%;
            letter-spacing: 0.06em;
            white-space: nowrap;
            color: ${(props) => props.theme.colors.white};
        }
    }
`;

export const CartoesStyles = styled.div`
    width: 190px;
    position: relative;
    .container-img {
        position: absolute;
        right: -30px;
        top: 137.6px;
        width: 20px;
        transition: all 0.5s;
        img {
            width: 100%;
            background: ${(props) => props.theme.colors.white};
        }
    }

    .esq {
        height: auto;
        overflow: hidden;
        width: 100%;
        transition: all 0.5s;

        .container-cartoes {
        }

        .item-card {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
        .container-card {
            margin-bottom: 8px;
            &.process {
                & > div {
                    cursor: not-allowed;
                    outline: none;
                }
            }
            ${Cartoes} {
                padding: 15px 13px 15px 10px;
                .container-bandeira {
                    margin-bottom: 16px;
                }
                .saldo {
                    span {
                        line-height: 98%;
                    }
                    .dinheiro {
                        padding: 0px;
                        align-items: flex-end;
                        width: 90%;
                        p {
                            font-size: 16.8717px;
                        }
                    }
                }
            }
        }
    }

    button {
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 120%;
            text-align: center;
            letter-spacing: 0.06em;
            color: ${(props) => props.theme.colors.secondary};
        }
        &.ver-mais {
            margin-left: auto;
            margin-right: auto;
            margin-top: 19px;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 120%;
            text-align: center;
            letter-spacing: 0.06em;
            color: ${(props) => props.theme.colors.secondary};
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            &.active {
                img {
                    transform: rotateX(180deg);
                }
            }
            img {
                margin-bottom: 13px;
                display: block;
                pointer-events: none;
            }
        }
    }

    &:before {
        content: "";
        display: block;
        height: 100%;
        width: 1px;
        min-height: calc(100vh - 39px - 166px - 80px);
        background-color: #cfcfcf;
        position: absolute;
        top: 0;
        left: 218px;
    }
`;

export const ModalStyles = styled.div`
    width: 375px;
    height: auto;

    ${Card} {
        overflow: scroll;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
    .container {
        width: 90%;
    }
`;

export const ModalExtratoStyles = styled.div`
    overflow: scroll;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const ModalCupomStyles = styled.div`
    width: 375px;
    height: auto;

    ${CardCupom} {
        overflow: scroll;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
    .container {
        width: 90%;
    }
`;

export const ModalSaldoStyles = styled.div`
    padding-bottom: 32px;

    overflow: scroll;
    .container {
        width: 90%;
    }
    ${CardSaldo} {
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }

    @media (min-width: 768px) {
        width: 375px;
    }
`;

export const ModaMovimentacaoStyles = styled.div`
    padding-bottom: 32px;

    overflow: scroll;
    .container {
        width: 90%;
    }
    ${CardMovimentacao} {
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }

    @media (min-width: 768px) {
        width: 375px;
    }
`;
