import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    padding-top: 47px;
    padding-bottom: 16px;
    border-bottom: 1px solid #9cabb8;
    margin-bottom: 51px;
    .header-tutorial-title {
        display: flex;
        align-items: center;
        .container-img {
            margin-right: 16px;
        }
        h3 {
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    .header-tutorial-search {
        margin-left: 55px;
        margin-right: auto;
        width: 487px;
        .container-input {
            position: relative;
            margin-bottom: 0px;
            .container-img {
                position: absolute;
                top: 50%;
                right: 23px;
                transform: translateY(-50%);
            }
            input {
                padding-left: 27px;
                padding-right: 42px;
            }
        }
    }

    .goBack {
        button {
            display: flex;
            align-items: center;
            color: ${(props) => props.theme.colors.primaryDark};
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            img {
                margin-right: 8px;
            }
        }
    }

    @media (max-width: 768px) {
        .header-tutorial-search {
            margin-right: auto;
            margin-left: auto;
            width: 50%;
        }
    }

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        margin-bottom: 31px;
        padding-bottom: 0px;
        border: none;
        .container-input {
            margin-top: 21px;
        }

        .header-tutorial-search {
            width: 100%;
        }

        .goBack {
            position: absolute;
            top: 47px;
            right: 0px;
        }
    }
`;
