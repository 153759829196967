import { Flex, Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { Button } from "../../Desktop/Button";
import { useUser } from "../../../Hook/user/UseUser";
import {
    foraDoHorarioExtendido,
    formatCurrentMoney,
    sleep,
    tempoFimPlataformaExtendido,
    tempoInicioPlataformaExtendido,
} from "../../../util/helper";
import IconOlho from "../../../assets/img/olho.svg";
import { Container } from "./styles";
import { UseModal } from "../../../Hook/Usemodal";
import { useCallback, useState } from "react";

export function Saldo() {
    const { user } = useUser();
    const { handleOpen } = UseModal();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [active, setActive] = useState(false);

    const fetchData = useCallback(async () => {
        if (active) {
            setShow(false);
            setActive(false);
            return;
        }
        setShow(false);
        setActive(true);
        await sleep(3000);
        setShow(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    return (
        <Container className="container">
            <div className={`container-saldo ${active ? "active" : ""}`} onClick={fetchData}>
                <Text
                    textTransform="uppercase"
                    color="primaryDark"
                    fontSize="xss"
                    mb="4px"
                >
                    SALDO DA CARTEIRA
                </Text>
                <Flex position="relative" >
                    <Text fontWeight="bold">R$</Text>
                    <Text
                        fontSize="mdl"
                        fontWeight="bold"
                        ml="-4px"
                        className={`dinheiro ${show ? "show" : ""}`}
                    >
                        {user.saldo
                            ? formatCurrentMoney(parseFloat(user.saldo))
                            : ""}
                    </Text>

                    <button
                        className={`container-olho ${active ? "hide" : ""}`}
                        disabled={active && !show}
                    >
                        <img src={IconOlho} alt="" />
                    </button>
                </Flex>
            </div>

            <Button
                className="ativar-cartao-avulso"
                onClick={() => {
                    if (foraDoHorarioExtendido()) {
                        handleOpen("horarioFuncionamento", {
                            text: (
                                <>
                                    <h2 className="success">
                                        Ops!
                                    </h2> 
                                    <p className="strong">
                                        Transferência da Carteira para o <br />
                                        Cartão disponível das{" "}
                                        {tempoInicioPlataformaExtendido}h às{" "}
                                        {tempoFimPlataformaExtendido}h <br />
                                        em dias úteis.
                                    </p>
                                
                                    <p>
                                        Por favor, retorne neste período.
                                    </p>
                                </>
                            ),
                        });
                        return;
                    }
                    history.push("/solicitar-resgate");
                }}
            >
                TRANSFERIR <br />
                SALDO PARA <br /> CARTÃO YETZPAY
            </Button>
        </Container>
    );
}
