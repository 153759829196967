import { Box, Flex, Text } from "alisson-application";
import { formatCurrentMoney } from "../../../util/helper";
import { useHistory } from "react-router-dom";
import { UseModal } from "../../../Hook/Usemodal";
import { useTranslation } from "../../../contexts/Localization";
import { Button } from "../ButtonSlim";
import { SucessoCupomStyles } from "./styles";
import imgSucessoCupom from "../../../assets/img/sucesso-cupom.svg";
import { usePassos } from "../../../Hook/UsePassos";
import { useCupom } from "../../../Hook/user/useCupom";
import { useState } from "react";
import { useUser } from "../../../Hook/user/UseUser";

export function SucessoCupom() {
    const { setState, state } = UseModal();
    const { t } = useTranslation();
    const history = useHistory();
    const { dados } = usePassos();
    const { cupomRequest } = useCupom();
    const { getUser } = useUser();
    const [loading, setLoading] = useState(false);
    const width = window.screen.width;
 
    async function fetchCupom() {
        setLoading(true);
        await cupomRequest({
            cupom: dados.cupom,
        });
        await getUser();

        setState({
            ...state,
            cupom: false,
            successGeral: {
                active: true,
                message: "Cupom adicionado com sucesso!",
                title: "Cupom",
            },
        });

        setLoading(false);
    }

    return (
        <SucessoCupomStyles className="container">
            <Flex className="text-icon">
                <img src={imgSucessoCupom} alt="" />

                <Text as="p">
                    Confira o valor do cupom que está adicionando e clique e
                    confirmar.
                </Text>
            </Flex>

            <Box mt="46px">
                <div className="valor-cupom">
                    <Text
                        textTransform="uppercase"
                        as="span"
                        color="primaryDark"
                        fontSize="xss"
                    >
                        {t("valor do cupom")}
                    </Text>
                    <Text as="span" color="primaryDark" fontSize="xss">
                        {dados?.resource?.valor &&
                            "R$ " + formatCurrentMoney(dados?.resource?.valor)}
                    </Text>
                </div>
                <div className="valor-cupom">
                    <Text
                        textTransform="uppercase"
                        as="span"
                        color="primaryDark"
                        fontSize="xss"
                    >
                        {t("saldo anterior")}
                    </Text>
                    <Text as="span" color="primaryDark" fontSize="xss">
                        {dados?.saldoAtual &&
                            "R$ " + formatCurrentMoney(dados?.saldoAtual)}
                    </Text>
                </div>
                <div className="valor-cupom">
                    <Text
                        textTransform="uppercase"
                        as="span"
                        color="primaryDark"
                        fontSize="xss"
                    >
                        {t("novo saldo")}
                    </Text>
                    <Text as="span" color="primaryDark" fontSize="xss">
                        {dados?.resource?.valor && dados?.saldoAtual
                            ? "R$ " +
                              formatCurrentMoney(
                                  parseFloat(dados?.resource?.valor) +
                                      parseFloat(dados?.saldoAtual)
                              )
                            : "R$ "}
                    </Text>
                </div>
            </Box>

            <Box mt="56px">
                <Button
                    isLoading={loading}
                    spiner="BallTriangle"
                    spinerColor="primary"
                    onClick={async () => {
                        await fetchCupom();

                        if (width <= 480) {
                            history.goBack();
                        }
                    }}
                >
                    Confirmar
                </Button>
            </Box>
        </SucessoCupomStyles>
    );
}
