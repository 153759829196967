import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 27px 35px 27px;
    gap: 21px;
    min-width: 327px;

    font-family: Oxanium;
    
    h2 {
        width:100%;
        flex:1;

        font-family: Oxanium;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;

        color: ${({ theme }) => theme.colors.failure};

        &.success{
            color: ${(props) => props.theme.colors.secondarydark};
        }
    }

    p{
        width:100%;

        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        color: ${(props) => props.theme.colors.textSubtle};

        &.strong{
            font-weight: 700;
        }
    }

    .buttonContainer{
        display: flex;
        flex-direction: column;
        gap:6px;
        align-items: center;

        width:273px;
        margin-top:30px;
    }
`;
