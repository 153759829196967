import { Box, Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../../contexts/Localization";
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../Button";
import { FinalizarStyles } from "./styles";
import imgResgate from "../../../assets/img/money.svg";

export function Finalizar() {
    const history = useHistory();
    const { t } = useTranslation();
    const { setState, state } = UseModal();
    const width = window.screen.width;

    return (
        <FinalizarStyles className="container">
            <Box>
                <img src={imgResgate} alt="" />
            </Box>

            <Text as="p">Crédito Solicitado <br /> com Sucesso!</Text>

            <Text textAlign="center" mb="54px">
            <strong>
                Seu pedido pode demorar <br />
                até 1 minuto para ser processado. 
            </strong>
            <br />
            Aguarde e atualize sua tela<br />
            após esse tempo
            </Text>

            <Button
                onClick={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({
                            ...state,
                            solicitarResgate: false,
                        });
                    }
                }}
            >
                {t("Ok")}
            </Button>
        </FinalizarStyles>
    );
}
