import styled, { css } from "styled-components";

interface ICodeInputProps {
    showError?: boolean;
}

export const CodeInputContainer = styled.section<ICodeInputProps>`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;

    > .react-code-input {
        display: flex !important;
        margin: 0 0 20px;

        input {
            border: 1px solid #c6c8ca !important;
            border-radius: 4px !important;

            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 100%;
            color: ${(props) => props.theme.colors.primary};
            box-shadow: none !important;
            margin: 2px !important;
            text-align: center;
            padding: 0 !important;

            ${({ showError }) =>
                showError &&
                css`
                    border-color: ${(props) => props.theme.colors.failure} !important;
                `}
        }
    }

    @media (min-width: 380px) {
        input {
            width: 100% !important;
            height: 65px !important;
        }
    }
`;
