/* eslint-disable react-hooks/exhaustive-deps */

import LogoImg from "../../assets/img/logo-menu.svg";
import { TimeIcon } from "../../assets/img/TimeIcon";

import { UseModal } from "../../Hook/Usemodal";
import { SimpleButton } from "../SimpleButton";
import { Container, LogoContainer, ModalContent } from "./styles";

export function DocumentoEmAnaliseIdWall() {
    const { handleClose, state } = UseModal();
    const dados = state.dados;

    function handleCloseModal() {
        if (dados?.login) {
            handleClose("documentoEmAnaliseIdWall");
        } else {
            window.location.reload();
        }
    }

    return (
        <Container>
            <LogoContainer>
                <img src={LogoImg} alt="logo" />
            </LogoContainer>

            <ModalContent>
                <TimeIcon />

                <h1>{"Aguarde a avaliação \ndo seu documento"}</h1>

                <p>
                    {
                        "Em até 3 dias úteis informaremos via \ne-mail e SMS sobre a liberação do seu acesso."
                    }
                </p>

                <SimpleButton onClick={handleCloseModal}>Ok</SimpleButton>
            </ModalContent>
        </Container>
    );
}
