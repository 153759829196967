import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../ButtonSlim";
import imgCartao from "../../../assets/img/solicitar-cartao.svg";
import { FinalizarStyles } from "./styles";
import { useUser } from "../../../Hook/user/UseUser";

export function Finalizar() {
    const history = useHistory();
    const { setState, state } = UseModal();
    const { getUser } = useUser();
    const width = window.screen.width;

    return (
        <FinalizarStyles flexDirection="column" className="container">
            <img src={imgCartao} alt="Solicitar Cartão" />

            <Text textTransform="uppercase" as="h3" fontWeight={600}>
                Seu Cartão Foi Ativado com Sucesso!
            </Text>

            <Text as="p">
                Transfira saldo da carteira para <br/>seu cartão para poder
                utilizá-lo.
            </Text>

            <Button
                mt="32px"
                onClick={async () => {
                    await getUser();

                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, ativarCartao: false });
                    }
                }}
            >
                Ok, Entendi
            </Button>
        </FinalizarStyles>
    );
}
