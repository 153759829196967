import { Text } from "alisson-application";
import { Button } from "../Button";
import imgAlert from "../../../assets/img/alerta-verde.svg";
import { Container } from "./styles";
import { UseModal } from "../../../Hook/Usemodal";
export function DisabledBoleto() {
    const { handleClose } = UseModal();

    return (
        <Container>
            <div className="container">
                <img className="img-close" src={imgAlert} alt="" />
                <Text
                    fontSize="lg"
                    textAlign="center"
                    color="primary"
                    mb="16px"
                    bold
                >
                    Atenção!
                </Text>
                <Text as="p" textAlign="center" mb="32px">
                    No momento, a função de Pagamento de Contas não está
                    disponível. Por favor, tente novamente mais tarde.
                </Text>

                <Button onClick={() => handleClose("disabledBoleto")}>
                    Ok, Entendi
                </Button>
            </div>
        </Container>
    );
}
