import styled from "styled-components";

export const Container = styled.button`
    min-width: 140px;
    padding: 4px 25px 4px 13px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    border-radius: 32px;
    height: 22px;
    transition: all 0.5s;
    background: #e1e2e3;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;

        letter-spacing: 0.08em;
        text-transform: uppercase;

        color: ${(props) => props.theme.colors.white};
        opacity: 0;
        transition: all 0.5s;
        position: absolute;
        top: 50%;
        transform: translateY(-45%);
        z-index: 10;
        &.pendente {
            color: ${(props) => props.theme.colors.primaryBright};
        }
        &.active {
            opacity: 1;
            transition: all 0.5s;
        }
    }

    &.active {
        padding: 4px 13px 4px 25px;
        transition: all 0.5s;
        background: #7dc277;
        &:before {
            left: 5px;
            transition: left 0.5s;
        }
    }

    &:before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        background: ${(props) => props.theme.colors.white};
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 82%;
        transform: translateY(-50%);
        transition: left 0.5s;
        z-index: 20;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
            0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }
`;
