import styled, { css } from "styled-components";

export const CollapseItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

interface ICollapseButton {
    isopen?: boolean;
    subitem?: boolean;
    $theme: "primary" | "secondary";
}

export const CollapseButton = styled.section<ICollapseButton>`
    --primary-bg-color: var(--primary-color);
    --secondary-bg-color: #fde09e;

    display: flex;
    justify-content: space-between;

    cursor: pointer;
    align-items: center;
    width: 100%;
    gap: 30px;

    svg {
        fill: ${(props) => props.theme.colors.black};
    }

    ${({ subitem }) =>
        subitem &&
        css`
            margin: 30px 0;
            padding: 8px 0;

            display: flex;
            align-items: center;
            justify-content: center;
            background: ${({ theme }) => `var(--${theme}-bg-color)`};
            border-radius: 100px;
        `}
`;

export const CollapseContent = styled.section`
    display: flex;

    > svg {
        margin-right: 10px;
    }
`;

interface ICollapseText {
    subitem?: boolean;
}

export const CollapseText = styled.section<ICollapseText>`
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2,
    p {
        font-family: Oxanium;
        font-size: 12px;
        font-style: normal;
        line-height: 124.5%;
    }

    h2 {
        font-weight: ${({ subitem }) => (subitem ? "700" : "800")};
        text-transform: uppercase;
    }

    p {
        font-weight: 400;
        padding: 0;
    }
`;

interface IChildrenContent {
    willclose?: boolean;
}

export const ChildrenContent = styled.div<IChildrenContent>`
    width: 100%;
    animation: show 0.4s normal;

    ${(props) =>
        props.willclose &&
        css`
            animation: hide 0.4s normal;
        `}

    @keyframes show {
        from {
            opacity: 0;
            transform: translateX(-50px);
        }

        to {
            opacity: 1;
            transform: translateX(0%);
        }
    }

    @keyframes hide {
        0% {
            opacity: 1;
            transform: translateX(0%);
        }

        100% {
            opacity: 0;
            transform: translateX(-50px);
        }
    }
`;
