import { Flex, Text, Box } from "alisson-application";
import { useHistory, Link } from "react-router-dom";
import { UseModal } from "../../../Hook/Usemodal";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useTranslation } from "../../../contexts/Localization";
import { IOSSwitch } from "../../System/Switch";
import imgSetaDir from "../../../assets/img/seta-dir-verde.svg";

import imgOlho from "../../../assets/img/olho.svg";
import imgCadeado from "../../../assets/img/cadeado.svg";
import imgChave from "../../../assets/img/senha-ativar-cartao.svg";
import imgComprovante from "../../../assets/img/comprovante.svg";
import imgTransferir from "../../../assets/img/transferir.svg";
// import imgCancelarCartao from "../../../assets/img/segunda-via.svg";
// import imgCancelarCartao from "../../../assets/img/cancelar.svg";
import imgBoleto from "../../../assets/img/boleto.svg";
import imgDinheiro from "../../../assets/img/$.svg";
import imgPerdaRoubo from "../../../assets/img/perda-roubo.svg";
import imgEntrega from "../../../assets/img/entrega.svg";

import { ModalStyles } from "./styles";
import {
    foraDoHorario,
    foraDoHorarioExtendido,
    getCampanha,
    tempoFimPlataforma,
    tempoInicioPlataforma,
    tempoFimPlataformaExtendido,
    tempoInicioPlataformaExtendido,
} from "../../../util/helper";
import { useUser } from "../../../Hook/user/UseUser";

export function ModalMovimentacao() {
    const history = useHistory();
    const { t } = useTranslation();
    const { state, setState, handleClose } = UseModal();
    const { cartao } = UseCartoes();
    const { user } = useUser();
    const hasPayment = getCampanha().allow_bill_payment;
    const hasActivePayment = !user?.all_campaigns_bill_payment_available
        ? user?.all_campaigns_bill_payment_available
        : getCampanha().allow_bill_payment_available;

    return (
        <ModalStyles>
            {hasPayment ? (
                <button
                    className={`pagamento-contas ${
                        !hasActivePayment ? "disabled" : ""
                    }`}
                    onClick={() => {
                        if (!hasActivePayment) {
                            setState({
                                ...state,
                                acoesCartaoMobile: false,
                                disabledBoleto: true,
                            });
                            return;
                        }

                        if (foraDoHorario()) {
                            setState({
                                ...state,
                                acoesCartaoMobile: false,
                                horarioFuncionamento: true,
                                dados: {
                                    text: (
                                        <>
                                            <h2 className="success">
                                                Ops!
                                            </h2> 
                                            <p className="strong">
                                                Pagamento de contas disponível{" "}
                                                <br /> das {tempoInicioPlataforma}h
                                                às {tempoFimPlataforma}h em dias
                                                úteis.
                                            </p>
                                            <p>
                                                Por favor, retorne neste período.
                                            </p>
                                        </>                                        
                                    ),
                                },
                            });
                            return;
                        }
                        setState({
                            ...state,
                            acoesCartaoMobile: false,
                            alertaBoleto: true,
                        });
                    }}
                >
                    <Flex alignItems="center">
                        <Box mr="8px" className="icone">
                            <img src={imgBoleto} alt="" />
                        </Box>
                        <Text
                            textTransform="uppercase"
                            fontSize="xss"
                            color="white"
                            bold
                        >
                            pagamento de Contas
                        </Text>
                    </Flex>
                </button>
            ) : (
                <></>
            )}

            <ul>
                <li
                    onClick={() => {
                        history.push("/alterar-senha-cartao");
                        setState({
                            ...state,
                            acoesCartaoMobile: false,
                        });
                    }}
                >
                    <Flex justifyContent="space-between" width="100%">
                        <Flex alignItems="center">
                            <Box mr="8px" className="icone">
                                <img src={imgChave} alt="" />
                            </Box>
                            <Text
                                textTransform="uppercase"
                                fontSize="xss"
                                color="secondary"
                            >
                                ATUALIZAR SENHA
                            </Text>
                        </Flex>

                        <Box>
                            <img src={imgSetaDir} alt="" />
                        </Box>
                    </Flex>
                </li>

                {hasPayment ? (
                    <li
                        onClick={() => {
                            history.push("/comprovantes-boleto");
                            setState({
                                ...state,
                                acoesCartaoMobile: false,
                            });
                        }}
                    >
                        <Flex justifyContent="space-between" width="100%">
                            <Flex alignItems="center">
                                <Box mr="8px" className="icone">
                                    <img src={imgComprovante} alt="" />
                                </Box>
                                <Text
                                    textTransform="uppercase"
                                    fontSize="xss"
                                    color="secondary"
                                >
                                    Comprovantes de Pagamento
                                </Text>
                            </Flex>

                            <Box>
                                <img src={imgSetaDir} alt="" />
                            </Box>
                        </Flex>
                    </li>
                ) : (
                    <></>
                )}

                <li
                    onClick={() => {
                        setState({
                            ...state,
                            perdaRoubo: true,
                            acoesCartaoMobile: false,
                        });
                    }}
                >
                    <Flex justifyContent="space-between" width="100%">
                        <Flex alignItems="center">
                            <Box mr="8px" className="icone">
                                <img src={imgPerdaRoubo} alt="" />
                            </Box>
                            <Text
                                textTransform="uppercase"
                                fontSize="xss"
                                color="secondary"
                            >
                                Perda ou Roubo
                            </Text>
                        </Flex>

                        <Box>
                            <img src={imgSetaDir} alt="" />
                        </Box>
                    </Flex>
                </li>

                <li
                    onClick={() => {
                        setState({
                            ...state,
                            acoesCartaoMobile: false,
                            limiteTarifas: true,
                        });
                    }}
                >
                    <Flex justifyContent="space-between" width="100%">
                        <Flex alignItems="center">
                            <Box mr="8px" className="icone">
                                <img src={imgDinheiro} alt="" />
                            </Box>
                            <Text
                                textTransform="uppercase"
                                fontSize="xss"
                                color="secondary"
                            >
                                Limites e Tarifas
                            </Text>
                        </Flex>

                        <Box>
                            <img src={imgSetaDir} alt="" />
                        </Box>
                    </Flex>
                </li>

                {cartao?.tipo === "cartao_fisico" ? (
                    <li
                        onClick={() => {
                            setState({
                                ...state,
                                entrega: true,
                                acoesCartaoMobile: false,
                            });
                        }}
                    >
                        <Flex justifyContent="space-between" width="100%">
                            <Flex alignItems="center">
                                <Box mr="8px" className="icone">
                                    <img src={imgEntrega} alt="" />
                                </Box>
                                <Text
                                    textTransform="uppercase"
                                    fontSize="xss"
                                    color="secondary"
                                >
                                    status entrega
                                </Text>
                            </Flex>

                            <Box>
                                <img src={imgSetaDir} alt="" />
                            </Box>
                        </Flex>
                    </li>
                ) : (
                    <></>
                )}

                <li
                    onClick={() => {
                        if (foraDoHorarioExtendido()) {
                            setState({
                                ...state,
                                acoesCartaoMobile: false,
                                horarioFuncionamento: true,
                                dados: {
                                    text: (
                                        <>
                                            <h2 className="success">
                                                Ops!
                                            </h2> 
                                            <p className="strong">
                                                Transferência da Carteira para{" "}
                                                <br />
                                                Cartão disponível das{" "}
                                                {tempoInicioPlataformaExtendido}h às{" "}
                                                {tempoFimPlataformaExtendido}h <br /> em dias
                                                úteis.
                                            </p>
                                            <p>
                                                    Por favor, retorne neste período.
                                            </p>
                                        </>                                        
                                    ),
                                },
                            });
                            return;
                        }

                        history.push("/transferir");
                        handleClose("acoesCartaoMobile");
                    }}
                >
                    <Flex justifyContent="space-between" width="100%">
                        <Flex alignItems="center">
                            <Box mr="8px" className="icone">
                                <img src={imgTransferir} alt="" />
                            </Box>
                            <Text
                                textTransform="uppercase"
                                fontSize="xss"
                                color="secondary"
                            >
                                TRANSFERIR ENTRE CARTÕES YETZPAY
                            </Text>
                        </Flex>

                        <Box>
                            <img src={imgSetaDir} alt="" />
                        </Box>
                    </Flex>
                </li>

                <li
                    onClick={() => {
                        setState({
                            ...state,
                            acoesCartaoMobile: false,
                            acaoBloquearCartao: true,
                        });
                    }}
                >
                    <Flex justifyContent="space-between" width="100%">
                        <Flex alignItems="center">
                            <Box mr="8px" className="icone">
                                <img src={imgCadeado} alt="" />
                            </Box>
                            <Text
                                textTransform="uppercase"
                                fontSize="xss"
                                color="secondary"
                            >
                                Bloqueio Temporário
                            </Text>
                        </Flex>

                        <Box>
                            <img src={imgSetaDir} alt="" />
                        </Box>
                    </Flex>
                </li>

                {/* <li
                    onClick={() => {
                        history.push("/cancelar-cartao");
                        handleClose("acoesCartaoMobile");
                    }}
                >
                    <Flex justifyContent="space-between" width="100%">
                        <Flex alignItems="center">
                            <Box mr="8px" className="icone">
                                <img src={imgCancelarCartao} alt="" />
                            </Box>
                            <Text
                                textTransform="uppercase"
                                fontSize="xss"
                                color="secondary"
                            >
                                CANCELAR CARTÃO
                            </Text>
                        </Flex>

                        <Box>
                            <img src={imgSetaDir} alt="" />
                        </Box>
                    </Flex>
                </li> */}

                {cartao?.tipo !== "cartao_fisico" &&
                cartao?.tipo !== "cartao_avulso" ? (
                    <li>
                        <Link
                            to={`dados-cartao`}
                            onClick={() => handleClose("acoesCartaoMobile")}
                        >
                            <Flex justifyContent="space-between" width="100%">
                                <Flex alignItems="center">
                                    <Box mr="8px" className="icone">
                                        <img src={imgOlho} alt="" />
                                    </Box>
                                    <Text
                                        textTransform="uppercase"
                                        fontSize="xss"
                                        color="secondary"
                                    >
                                        {t("visualizar dados")}
                                    </Text>
                                </Flex>

                                <Box>
                                    <img src={imgSetaDir} alt="" />
                                </Box>
                            </Flex>
                        </Link>
                    </li>
                ) : null}
                <li>
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        pt="32px"
                    >
                        <Text fontSize="xs">ATIVAR SMS DE TRANSAÇÕES</Text>
                        <IOSSwitch
                            checked={cartao?.acg_sms_service ? true : false}
                            onChange={() => {
                                setState({
                                    ...state,
                                    acoesCartaoMobile: false,
                                    confirmaToggleSms: true,
                                });
                            }}
                        />
                    </Flex>
                </li>
            </ul>
        </ModalStyles>
    );
}
