import styled, { css } from "styled-components";

interface IButtonContainer {
    $theme?: "primary" | "secondary";
    medium?: boolean;
}

export const ButtonContainer = styled.button<IButtonContainer>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 380px;
    min-height: 40px;
    padding: 2.5px 20px;
    border-radius: 100px;
    border: none;

    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.secondary};

    font-family: "Oxanium";
    font-size: ${({ medium }) => (medium ? "14px" : "16px")};
    font-weight: bold;

    transition: transform 0.4s;

    svg {
        margin-right: 10px;
    }

    ${({ disabled }) =>
        disabled
            ? css`
                  cursor: not-allowed;
              `
            : css`
                  &:hover {
                      transform: scale(1.025);
                  }
              `}
`;
