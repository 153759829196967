import styled from "styled-components";
import { Flex } from "alisson-application";
import { Button as ButtonEmpty } from "../Button";

export const Container = styled.div`
    width: calc(100% - 192px);
    padding-left: 91px;
    .container-loader-grafico {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 500px;
    }
`;

export const SaldoStyles = styled(Flex)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.disabled};
    border-radius: 4px;
    padding: 23px 22px;
    margin-bottom: 28px;
    .container-saldo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: ${({ theme }) => theme.fonts.xss};
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.primary};
            position: relative;
            margin-right: 39px;
            &:after {
                content: "";
                width: 100%;
                height: 7px;
                width: 7px;
                border-radius: 50%;
                background-color: #c4c4c4;
                position: absolute;
                top: 50%;
                right: -23px;
                transform: translateY(-50%);
            }
        }

        h4 {
            font-style: normal;
            font-weight: 600;
            font-size: ${({ theme }) => theme.fonts.mdl};
            line-height: 100%;
            color: ${({ theme }) => theme.colors.black};
        }
    }
`;

export const AcoesStyles = styled(Flex)`
    display: flex;
    align-items: center;
    height: 57px;
    padding: 0 62px;
    /* padding-right: 60px; */
    justify-content: space-between;
    border: 0.951567px solid #e1e2e3;
    margin-bottom: 18px;
    background: #f5f5f5;
    border-radius: 30px;
    position: relative;
    z-index: 10;

    &.active {
        &:before {
            opacity: 1;
            transition: all 0.5s;
        }
    }

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        background: linear-gradient(
            270.04deg,
            #f5f5f5 10.82%,
            rgba(245, 245, 245, 0) 24.95%
        );
        z-index: 20;
        opacity: 0;
        border-radius: 30px;
        pointer-events: none;
        transition: all 0.5s;
    }

    .container-transferir {
        width: 203px !important;
    }

    .controles {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98%;
        z-index: 30;
        height: 0px;
        & > div {
            cursor: pointer;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: ${(props) => props.theme.colors.white};
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    button {
        font-style: normal;
        font-weight: 600;
        font-size: ${({ theme }) => theme.fonts.xss};
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.secondary};
        background: transparent;
        text-align: left;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 40;
        &.btn-boleto {
            border-radius: 32px;
            justify-content: center;
            background: ${({ theme }) => theme.colors.secondary};
            width: 163px;
            height: 40px;
            color: ${(props) => props.theme.colors.white};
            &.disabled {
                opacity: 0.5;
            }
        }

        &:not(:first-child) {
            &:before {
                content: "";
                background-color: #e1e2e3;
                display: block;
                height: 10px;
                width: 1px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -20px;
            }
        }

        img {
            margin-right: 8px;
        }
    }
    .swiper-container {
        position: relative;
        .swiper-wrapper {
            align-items: center;
        }
        .swiper-slide {
            position: relative;
            display: flex;
            justify-content: center;
            &:first-child {
                &:before {
                    display: none;
                }
            }
            &:last-child {
                &:before {
                    display: none;
                }
            }
            &:before {
                content: "";
                display: block;
                width: 1px;
                height: 83px;
                position: absolute;
                top: 0px;
                right: 0px;
                background-color: #e1e2e3;
                pointer-events: none;
            }
        }
    }
`;

export const Button = styled.button`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondarybright};
    box-sizing: border-box;
    border-radius: 600px;
    text-align: center;
    padding: 10px 16px;
    white-space: nowrap;
`;

export const MesesStyles = styled.div`
    position: relative;
    margin-bottom: 32px;
    .controles {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        div {
            cursor: pointer;
        }
    }

    .swiper-slide {
        width: 790px;
        &.swiper-slide-active {
            ${Button} {
                background: ${(props) => props.theme.colors.disabled};
                transition: background 0.5s;
                color: ${(props) => props.theme.colors.secondarydark};
            }
        }
    }
`;

export const GraficoStyles = styled.div`
    padding-bottom: 32px;
    border-bottom: 1px solid #e1e2e3;
    @media (max-width: 1024px) {
        border-bottom: none;
    }
`;

export const MovimentacaoStyles = styled.div`
    padding-top: 32px;
    .header-movimentacao {
        display: grid;
        grid-template-columns: 1fr 1fr 80px 80px;
        grid-gap: 64px;
    }

    p,
    span {
        &.data-hora {
            text-align: center;
        }
    }
    .container-operacoes {
        width: 260px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    p {
        font-style: normal;
        font-size: ${({ theme }) => theme.fonts.xs};
        line-height: 160%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        text-align: right;
        &.entrada {
            position: relative;
            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 12px solid
                    ${(props) => props.theme.colors.secondary};
                position: absolute;
                top: 3px;
                left: -15px;
            }
        }

        &.saida {
            position: relative;
            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 12px solid ${(props) => props.theme.colors.warning};
                position: absolute;
                top: 4px;
                left: 5px;
            }
        }

        &.active {
            font-weight: 600;
        }
    }

    .item {
        display: grid;
        grid-template-columns: 1fr 1fr 80px 80px;
        grid-gap: 64px;
        justify-items: space-between;
        align-items: center;
        height: auto;
        border-bottom: 1px solid #e4e4e4;
        padding-top: 8px;
        padding-bottom: 8px;
        &.reversed {
            p,
            span,
            small {
                text-decoration: line-through;
            }
        }
        &:first-child {
            padding-top: 24px;
        }
        &:last-child {
            border-bottom: none;
        }
        p {
            font-style: normal;
            font-weight: 600;
            font-size: ${({ theme }) => theme.fonts.xs};
            line-height: 160%;
            color: ${(props) => props.theme.colors.primary};
            position: relative;
            padding-left: 29px;
            text-align: left;
            &.reversed {
                position: relative;
            }
            &:before {
                position: absolute;
                top: 4px;
                left: -0px;
            }
        }

        span {
            font-style: normal;
            font-weight: 500;
            font-size: ${({ theme }) => theme.fonts.xs};
            line-height: 100%;
            color: ${(props) => props.theme.colors.primaryDark};
        }

        small {
            font-style: normal;
            font-weight: 500;
            font-size: ${({ theme }) => theme.fonts.xs};
            line-height: 100%;
            text-align: right;
            white-space: nowrap;
            &.saida {
                color: ${(props) => props.theme.colors.warning};
            }

            &.entrada {
                color: ${(props) => props.theme.colors.secondary};
            }
        }
    }
`;

export const EmptyStyles = styled.div`
    height: 232px;
    background-color: ${(props) => props.theme.colors.disabled};
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 23px;
    }
    ${ButtonEmpty} {
        width: 210px;
        margin: 0 auto;
        text-transform: none;
        font-size: 14px;
        font-weight: 700;
    }
`;

export const InforCartaoStyles = styled(Flex)`
    padding-left: 32px;
    margin-bottom: 35px;
    .container-info {
        display: flex;
    }

    .container-card {
        margin-right: 62px;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 1px;
            height: 83px;
            position: absolute;
            top: 0px;
            right: -31px;
            background-color: #e1e2e3;
        }
    }
    .container-card,
    .container-value {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
            margin-bottom: 12px;
        }
        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${({ theme }) => theme.colors.primary};
            margin-bottom: 2px;
            text-transform: capitalize;
        }

        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 25.4082px;
            line-height: 100%;
            color: ${({ theme }) => theme.colors.secondary};
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.08em;
            color: ${({ theme }) => theme.colors.primary};
        }
    }

    .container-value {
        padding-top: 11px;
        h3 {
            margin-bottom: 5px;
            &.saldo {
                text-transform: unset;
            }
        }
    }

    .container-card {
        h3 {
            margin-bottom: 12px;
        }
    }
`;
