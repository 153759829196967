import styled from "styled-components";
export const Container = styled.div`
    form {
        display: flex;
        flex-direction: column;
        .container-element-backoffice {
            &:nth-child(2) {
                order: 4;
            }
        }
    }

    .container-phones {
        display: grid;
        grid-template-columns: 59px 59px 1fr;
        grid-gap: 0 14px;
        order: 2;
        .container-element-backoffice {
            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 2;
            }
            &:nth-child(3) {
                order: 3;
            }
        }
    }

    .isActive {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        order: 4;
        small {
            font-size: 12px;
            color: ${(props) => props.theme.colors.black};
            margin-right: 8px;
            text-transform: uppercase;
            &.active {
                color: ${({ theme }) => theme.colors.secondary};
                font-weight: 700;
            }
            &.disabled {
                color: ${({ theme }) => theme.colors.failure};
                font-weight: 700;
            }
        }
    }
`;
