import styled from "styled-components";
import { Flex } from "alisson-application";

export const Container = styled.div`
    p {
        text-align: center;
        padding-top: 19px;
        padding-bottom: 26px;
        width: 296px;
        margin: 0 auto;
    }
`;

export const FormStyles = styled.div`
    margin: 0 auto;
    width: 90%;
    margin-bottom: 23px;
    small {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.secondary};
        font-weight: bold;
        margin: 12px 0px 17px;
        padding-left: 30px;
        position: relative;
        display: block;
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background: ${(props) => props.theme.colors.primaryDark};
        }
    }
    button {
        &.open-modal-cvv {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            text-align: left;
            color: ${(props) => props.theme.colors.secondary};
            width: 100%;
            margin-bottom: 68px;
            padding-left: 30px;
        }
    }
`;

export const ModalStyles = styled.div`
    padding-bottom: 22px;
    padding-top: 69px;
    position: relative;
    p {
        text-align: center;
        width: 288px;
        margin: 0 auto;
        margin-bottom: 36px;
    }

    img {
        &.cartao {
            width: 288px;
            margin: 0 auto;
            display: block;
        }

        &.icone-cartao-ativar {
            position: absolute;
            top: -90px;
            left: 50%;
            transform: translateX(-50%);
            pointer-events: none;
        }
    }
`;

export const FinalizarStyles = styled(Flex)`
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-bottom: 35px;
    img {
        width: 48px;
    }

    h3 {
        margin-top: 21px;
        margin-bottom: 21px;
        font-size: 20px;
        font-weight: 700;
        text-transform:none;
        color: ${(props) => props.theme.colors.secondary};
        text-align: center;
        line-height:140%
    }

    p {
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0 auto;
        margin-bottom: 56px;
    }
`;
