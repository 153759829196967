import styled from "styled-components";

export const Container = styled.div`
    padding: 50px 0 35px 0;
    .container-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-title {
        margin: 21px 0;
        h3 {
            font-size: 20px;
            text-align: center;
            color: ${(props) => props.theme.colors.secondary};
            font-weight: 700;
        }
    }

    .container-description {
        margin-bottom: 39px;
        p {
            font-size: 14px;
            text-align: center;
            color: ${(props) => props.theme.colors.black};
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                background: none;
                border: none;
                color: ${(props) => props.theme.colors.secondary};
                font-weight: 600;
                font-size: 14px;
                cursor: pointer;
                text-decoration: underline;
                margin: 0px 5px;
            }
        }
    }

    .container-aviso {
        background-color: #f2f2f2;
        border-radius: 5px;
        width: 306px;
        height: 119px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 56px;
        p {
            text-align: center;
            font-size: 14px;
            text-align: center;
            color: ${(props) => props.theme.colors.black};
            font-weight: 400;
            strong {
                color: ${(props) => props.theme.colors.secondary};
            }
        }
    }

    .container-btn {
        width: 320px;
        margin: 0 auto;
        button {
            background-color: #59b852;
        }
    }
`;
