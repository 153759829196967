import styled from "styled-components";
import imgSetaEsq from "../../../assets/img/seta-esq-branca.svg";

export const Container = styled.div`
    width: 288px;
    margin: 0 auto;
    position: relative;

    .container-descricao {
        margin-bottom: 15px;
        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.primary};
            text-align: left;
            padding: 0px;
            margin: 0px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.primary};
            text-align: left;
            padding: 0px;
            margin: 0px;
            text-transform: uppercase;
        }
    }

    .container-text {
        position: absolute;
        top: 53px;
        left: 10px;
        .numero {
            span {
                font-family: "Inter";
                font-style: normal;
                font-weight: 700;
                font-size: 11.7431px;
                line-height: 140%;
                color: ${({ theme }) => theme.colors.white};
                margin-bottom: 3px;
                display: block;
            }
        }

        .conta {
            margin-bottom: 16px;
            span {
                font-family: "Inter";
                font-style: normal;
                font-weight: 700;
                font-size: 11.7431px;
                line-height: 140%;
                color: ${({ theme }) => theme.colors.white};
                position: relative;
                .container-destaque {
                    position: absolute;
                    top: -6px;
                    left: -6px;
                    width: 107px;
                    height: 34px;
                    border: 1px solid ${(props) => props.theme.colors.white};
                    border-radius: 4px;
                    &:after {
                        content: "Conta";
                        display: block;
                        position: absolute;
                        top: 45%;
                        right: -56px;
                        transform: translateY(-50%);
                        width: 34px;
                        height: 15px;
                        z-index: 20;
                    }
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: -17px;
                        transform: translateY(-50%);
                        background-color: ${(props) =>
                            props.theme.colors.white};
                        -webkit-mask: url(${imgSetaEsq}) no-repeat;
                        width: 11px;
                        height: 7px;
                        z-index: 20;
                    }
                }
            }
        }

        .data-code {
            display: flex;
            justify-content: space-between;
            width: 78px;
            margin-bottom: 19px;
            span {
                font-family: "Inter";
                font-style: normal;
                font-weight: 700;
                font-size: 7.64551px;
                line-height: 140%;
                color: ${({ theme }) => theme.colors.white};
                white-space: nowrap;
                &:last-child {
                    position: relative;
                    .container-destaque {
                        position: absolute;
                        top: -2px;
                        left: -6px;
                        width: 43px;
                        height: 34px;
                        border: 1px solid ${(props) => props.theme.colors.white};
                        border-radius: 4px;
                        &:after {
                            content: "CVV";
                            display: block;
                            position: absolute;
                            top: 50%;
                            right: -56px;
                            transform: translateY(-50%);
                            width: 34px;
                            height: 15px;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 11.7431px;
                            line-height: 140%;
                            z-index: 20;
                        }
                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 50%;
                            right: -17px;
                            transform: translateY(-50%);
                            background-color: ${(props) =>
                                props.theme.colors.white};
                            -webkit-mask: url(${imgSetaEsq}) no-repeat;
                            width: 11px;
                            height: 7px;
                            z-index: 20;
                        }
                    }
                }
            }
        }

        .container-name {
            span {
                font-family: "Inter";
                font-style: normal;
                font-weight: 700;
                font-size: 8.47205px;
                line-height: 140%;
                color: ${({ theme }) => theme.colors.white};
                text-transform: uppercase;
            }
        }
    }
`;
