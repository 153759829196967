import styled from "styled-components";
import imgLupa from "../../../../../assets/img/lupa.svg";

export const Container = styled.div`
    margin-top: -40px;
    .container-loading {
        position: relative;
        min-height: 683px;
    }
`;

export const HeaderStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .container-input-system {
        margin-bottom: 0px;
        width: 300px;
        position: relative;
        margin-right: 44px;

        border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
        input {
            padding-left: 0px;
            border: none;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 0px;
            left: unset;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.colors.secondary};
            -webkit-mask: url(${imgLupa}) no-repeat;
            width: 19px;
            height: 17px;
            z-index: 20;
            transition: opacity 0.5s;
            pointer-events: none;
        }
    }
    .container-usuarios {
        span {
            display: inline-block;
            margin-right: 16px;

            small {
                color: ${(props) => props.theme.colors.black};
                font-weight: bold;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .container-btn {
        width: 120px;
    }
`;
