import { useEffect, useState } from "react";
import Mask from "react-input-mask";
import { Field, ErrorMessage, FormikProps, Formik, Form } from "formik";
import { formataCPF } from "../../util/helper";

import { useUser } from "../../Hook/user/UseUser";
// import { UseModal } from "../../Hook/Usemodal";
import { usePerfil } from "../../Hook/user/usePerfil";
import { useTranslation } from "../../contexts/Localization";
import Select, { options } from "../../components/NovoSelect";
import { Button } from "../Mobile/ButtonSlim";
import { ButtonEmail } from "./button";

import { initalValues, Myform, validation } from "./config";

export function FormPerfil() {
    const { user, confirmEmailGenerate } = useUser();
    // const { setState, state } = UseModal();
    const { formatValues, setDataSensible } = usePerfil();
    const { t } = useTranslation();
    const [values, setValues] = useState(initalValues);

    const genero: options[] = [
        {
            label: "Masculino",
            value: 1,
        },
        {
            label: "Feminino",
            value: 2,
        },
        {
            label: "Outros",
            value: 3,
        },
    ];

    async function handleGenarateEmail() {
        await confirmEmailGenerate();
    }

    // async function handleSubmit(
    //     values: Myform,
    //     actions: FormikHelpers<Myform>
    // ) {
    //     if (values.nome_da_mae.trim().indexOf(" ") === -1) {
    //         actions.setFieldError("nome_da_mae", "Preencha o nome completo");
    //         return;
    //     }
    //     if (values.name.trim().indexOf(" ") === -1) {
    //         actions.setFieldError("name", "Preencha o nome completo");
    //         return;
    //     }

    //     if (values.nome_cpf.trim().indexOf(" ") === -1) {
    //         actions.setFieldError("nome_cpf", "Preencha o nome completo");
    //         return;
    //     }

    //     await updateUser(values);
    //     setState({ ...state, perfil: false, menu: true });
    // }

    useEffect(() => {
        if (Object.values(user).length > 0) {
            const data = user?.pessoa?.data_nascimento.replace(
                /^([0-9]{4})-([0-9]{2})-([0-9]{2})/g,
                "$3/$2/$1"
            );
            setValues({
                document: formataCPF(user.document),
                name: user?.name,
                email: user?.pessoa?.email ? user?.pessoa?.email : "",
                phone_number: user?.phone_number.replace("55", ""),
                apelido: user?.pessoa?.apelido,
                data_nascimento: data,
                nome_cpf: user?.pessoa?.nome_cpf || "",
                genero: user?.pessoa?.genero,
            });
        }
    }, [user]);

    return (
        <Formik
            enableReinitialize
            initialValues={values}
            validationSchema={validation}
            onSubmit={async (currentValues, actions) => {
                const onlyChanges = {};
                Object.keys(currentValues).forEach((key) => {
                    if (currentValues[key] !== values[key]) {
                        onlyChanges[key] = currentValues[key];
                    }
                });

                formatValues(onlyChanges as Myform);
                setDataSensible(true);
            }}
        >
            {(props: FormikProps<Myform>) => {
                const { setFieldValue, values: formValues } = props;
                const { phone_number, name, data_nascimento } = formValues;

                const checkInitialValues = JSON.stringify(values);
                const checkValues = JSON.stringify(formValues);

                return (
                    <Form>
                        <div className={`container-input user active disabled`}>
                            <Field
                                name="name"
                                value={name}
                                placeholder={t("Nome")}
                            />
                        </div>
                        <ErrorMessage name="name" component="span" />

                        <div className={`container-input user active`}>
                            <Field
                                mask="999.999.999-99"
                                placeholder="CPF"
                                name="document"
                                type="tel"
                            />
                        </div>
                        <ErrorMessage name="document" component="span" />
                        <div className={`container-input user`}>
                            <Field name="apelido" placeholder={t("apelido")} />
                        </div>
                        <ErrorMessage name="apelido" component="span" />
                        <div className={`container-input calendario`}>
                            <Mask
                                mask="99/99/9999"
                                placeholder="data de nascimento"
                                name="data_nascimento"
                                type="tel"
                                onChange={(e) => {
                                    setFieldValue(
                                        "data_nascimento",
                                        e.target.value
                                    );
                                }}
                                value={data_nascimento}
                            />
                        </div>
                        <ErrorMessage name="data_nascimento" component="span" />
                        <Select
                            tipo="user"
                            label={t("gênero")}
                            options={genero}
                            initalValue={genero.find(
                                (genero) =>
                                    genero?.label?.toLowerCase() ===
                                    values?.genero?.toLowerCase()
                            )}
                            onChange={(values) =>
                                setFieldValue(
                                    "genero",
                                    values.label.toUpperCase()
                                )
                            }
                        />
                        <ErrorMessage name="genero" component="span" />
                        <div className={`container-input user active disabled`}>
                            <Field
                                name="nome_cpf"
                                placeholder={t("nome igual ao cpf")}
                            />
                        </div>
                        <ErrorMessage name="nome_cpf" component="span" />
                        <div className={`container-input contato`}>
                            <Field
                                name="email"
                                type="text"
                                placeholder="E-MAIL"
                            />
                        </div>
                        <ErrorMessage name="email" component="span" />
                        <div className="container-btns">
                            <ButtonEmail
                                type="button"
                                className={`${
                                    user?.pessoa?.email_confirmed
                                        ? "active"
                                        : ""
                                }`}
                            >
                                {user?.pessoa?.email_confirmed
                                    ? t("VERIFICADO")
                                    : t("NÃO VERIFICADO")}
                            </ButtonEmail>

                            {!user?.pessoa?.email_confirmed && (
                                <button
                                    type="button"
                                    onClick={handleGenarateEmail}
                                    className="btn-verificar"
                                >
                                    ENVIAR NOVAMENTE
                                </button>
                            )}
                        </div>
                        <div className={`container-input celular`}>
                            <Mask
                                mask="(99) 99999-9999"
                                placeholder={t("celular")}
                                name="phone_number"
                                type="tel"
                                onChange={(e) => {
                                    setFieldValue(
                                        "phone_number",
                                        e.target.value
                                    );
                                }}
                                value={phone_number}
                            />
                        </div>
                        <ErrorMessage name="phone_number" component="span" />
                        <Button
                            spinerColor="primary"
                            disabled={checkInitialValues === checkValues}
                            spiner="BallTriangle"
                        >
                            {t("Alterar")}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
}
