import { UseModal } from "../../../Hook/Usemodal";
import imgError from "../../../assets/img/modal-error.svg";
import { Button } from "../Button";
import { Container } from "./styles";

export function DocumentoErro() {
    const { state, setState } = UseModal();
    return (
        <Container>
            <div className="container-img">
                <img src={imgError} alt="" />
            </div>
            <div className="container-text">
                <h4>ERRO NO ENVIO!</h4>
                <br />
                <p>
                    Desculpe, houve um erro no envio do arquivo, <br /> tente
                    novamente mais tarde.
                </p>
            </div>

            <Button
                spinerColor="primary"
                spiner="BallTriangle"
                onClick={() => {
                    setState({
                        ...state,
                        verificacaoDocument: true,
                        errorDocumento: false,
                    });
                }}
            >
                Ok, Entendi
            </Button>
        </Container>
    );
}
