import { Text } from "alisson-application";
import { useTranslation } from "../../../contexts/Localization";
import { useHistory } from "react-router-dom";
import { UseModal } from "../../../Hook/Usemodal";
import { usePassos } from "../../../Hook/UsePassos";
import { Button } from "../ButtonSlim";
import { arrVirtual } from "../SolicitarCartao";
import imgCartao from "../../../assets/img/solicitar-cartao.svg";
import { FinalizarStyles } from "./styles";

export function Finalizar() {
    const width = window.screen.width;
    const history = useHistory();
    const { t } = useTranslation();
    const { setState, state } = UseModal();
    const { setPassos } = usePassos();

    return (
        <FinalizarStyles flexDirection="column" className="container">
            <img src={imgCartao} alt="Solicitar Cartão" />

            <Text as="h3" fontWeight={600}>
                {t("Cartão Solicitado com Sucesso!")}
            </Text>

            <Text as="p">
                Seu cartão será liberado em instantes em <strong>meus cartões</strong> para <strong>inserir crédito</strong>.
            </Text>

            <Button
                onClick={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, novoCartao: false });
                    }

                    setPassos(arrVirtual);
                }}
            >
                Ok, {t("Entendi")}
            </Button>
        </FinalizarStyles>
    );
}
