import styled from "styled-components";
import { Button } from "../Mobile/Button";

export const Container = styled.div`
    width: 90%;
    margin: 0 auto;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        margin: 32px 0px;
    }

    .container-input {
        &.cep {
            display: flex;
            align-items: center;
            input {
                width: 238px;
                margin-right: 8px;
            }
        }
        &.user {
            &.select {
                margin-bottom: 8px;
            }
        }

        input {
            height: 40px;
            padding: 0 44px;
            text-transform: none;
        }


        &.uf{
            input{
                text-transform: uppercase;
            }   
        }

        &.casa:before,
        &.endereco:before{
            left: 12px;
        }
    }
    ${Button} {
        &.btn-lupa {
            background-color: ${(props) =>
                props.theme.colors.secondary} !important;
            background: unset;
            position: relative;
            z-index: 99;
        }
    }
`;
